import { useAdminStore, useAuthStore} from '@/stores';

export default {
  name: "Admin",
  components: {
  },
  data() {
    return {
      admin_store: useAdminStore(),
      authStore:useAuthStore()
    }
  },

  mounted() {
    this.admin_store.fetch_admin()
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};