import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";
import Chart from 'chart.js/auto'

//Import SatNet
import { cns11, cns11gain, cns11_gnt, cns11_gntgain, } from "@/assets/js/satnet/cns11.js";
import { apstar5, apstar5gain, apstar5_gnt, apstar5_gntgain } from "@/assets/js/satnet/apstar5.js";
import { apstar92, apstar92gain, apstar92_gnt, apstar92_gntgain } from "@/assets/js/satnet/apstar9.js";
import { abs, absgain, abs_gnt, abs_gntgain } from "@/assets/js/satnet/abs.js";
import { ocn, ocngain, ocn_gnt, ocn_gntgain } from "@/assets/js/satnet/oceanbeam.js";
import { hts, htsgain, hts_gnt, hts_gntgain } from "@/assets/js/satnet/hts.js";
import { beam3, beam3gain, beam3_gnt, beam3_gntgain } from "@/assets/js/satnet/beam3.js";
import { horizon, horizongain, horizon_gnt, horizon_gntgain } from "@/assets/js/satnet/horizon.js";
import { utelsat, utelsatgain, utelsat_gnt, utelsat_gntgain } from "@/assets/js/satnet/utelsat.js";


export const useSailinkStore = defineStore({
    id: 'sailink',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        authStore: useAuthStore(),
        sailink_table: null,
        sailinkuser_table: null,
        sailinktrack_table: null,
        is_showonmap: null,
        search_sailink: null,
        search_sailinkuser: null,
        search_sailinktrack: null,
        quotaData: null,
        quotaTitle: '',
        sailink_data: null,
        ip_addr: null,
        ln_status: null,
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 6,
        mapsailink_data: null,
        mapsailink_data_date: null,
        page_off: 10,
        satnet_id: null,
        switch_true: false,
        satnetmodal_content: 'Satnet will be switch',
        satnet_output: null,
        page_off_tracking: 10,
        show_range: "Last 24 Hours",
        export_range: "24",
        mapsailink_id: null,
        loading_range: false,
        tracking_layer: L.layerGroup(),
        target_layer: L.layerGroup(),
        link_layer: L.layerGroup(),
        search_layer: L.layerGroup(),
        marker_tracking: [],
        moving_marker: null,
        tracking_path: null,
        tracking_latLng: null,
        tracking_rotation: null,
        interval_tracking: null,
        sailink_map: null,
        date_range: null,
        isBottom: false,
        quotaChart: null,
        change_vesselname: null,
        changevesselname_data: null,
        change_vesselid: null,
        loading_changename: false,
        mrtg_data: null,
        mrtg_title: {
            1: "Hourly (1 Minute Average)",
            2: "Daily (5 Minute Average)",
            3: "Weekly (30 Minute Average)",
            4: "Monthly (2 Hour Average)",
            5: "Yearly (1 Day Average)",
        },

        speedalert_data: null,
        quotaalert_data: null,

        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        th_sailink_arr: [
            "No",
            "Vessel Name",
            "Quota",
            "Company",
            "Group",
            "S/N",
            "",
            "",
            "",
            ""
        ],
        th_sailink_track_arr: [
            "#",
            "Latitude,Longitude\n(DMS)",
            "Heading\n(Degree)",
            "Speed\n(Knots)",
            "Distance\n(Nmi)",
            "RX SNR\n(db)",
            "RX MODCOD",
            "TX Power\n(db)",
            "SatNet",
            "Date/Time\n(GMT+7)",
            "Link Status"
        ],
        th_sailink_user_arr: [
            "#",
            "Name",
            "Email",
            "Role",
            "Last Login"
        ],
        td_button: [
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'button_mrtg', b: 'btn-success' },
            { a: 'button_ping', b: 'btn-secondary' },
            { a: 'button_notif', b: 'btn-secondary' },
            { a: 'button_showonmap', b: 'btn-primary' }
        ],
        td_sailink_arr: [
            4,
            14.7,
            7.7,
            16.7,
            6.7,
            6.7,
            7.7,
            7.7,
            10.4,
            13.7,
        ],
        td_sailink_track_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ],



        //SATNET

        //Skyreach 1
        path_cns11: cns11,
        gain_cns11: cns11gain,
        path_cns11gnt: cns11_gnt,
        gain_cns11gnt: cns11_gntgain,
        //Skyreach 2
        path_apstar5: apstar5,
        gain_apstar5: apstar5gain,
        path_apstar5gnt: apstar5_gnt,
        gain_apstar5gnt: apstar5_gntgain,

        //Apstar 9
        path_apstar9: apstar92,
        gain_apstar9: apstar92gain,
        path_apstar9gnt: apstar92_gnt,
        gain_apstar9gnt: apstar92_gntgain,

        //ABS 2A
        path_abs: abs,
        gain_abs: absgain,
        path_absgnt: abs_gnt,
        gain_absgnt: abs_gntgain,

        //SR1 Maritime
        path_ocn: ocn,
        gain_ocn: ocngain,
        path_ocngnt: ocn_gnt,
        gain_ocngnt: ocn_gntgain,

        //SR2 HTS BEAM#4
        path_beam4: hts,
        gain_beam4: htsgain,
        path_beam4gnt: hts_gnt,
        gain_beam4gnt: hts_gntgain,

        //SR2 HTS BEAM#3
        path_beam3: beam3,
        gain_beam3: beam3gain,
        path_beam3gnt: beam3_gnt,
        gain_beam3gnt: beam3_gntgain,

        //Horizon
        path_hrz: horizon,
        gain_hrz: horizongain,
        path_hrzgnt: horizon_gnt,
        gain_hrzgnt: horizon_gntgain,

        //Utelsat
        path_utls: utelsat,
        gain_utls: utelsatgain,
        path_utlsgnt: utelsat_gnt,
        gain_utlsgnt: utelsat_gntgain,


        EIRP: false,
        GNT: false,
        SKYR1: false,
        SKYR2: false,
        APST9: false,
        ABS2A: false,
        SR1MR: false,
        BEAM4: false,
        BEAM3: false,
        HRZ: false,
        UTLS: false,


        polygonColor: {
            skyr1: "green",
            skyr2: "blue",
            apst9: "purple",
            abs2a: "orange",
            sr1mr: "#F7038B",
            beam4: "#DB6B0F",
            beam3: "#DB6B0F",
            hrz: "#DBC70f",
            utls: "#0CAD93"
        },


    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Sailink Data
        async fetch_sailink() {
            await api.get(`/api/v1/navplus/sailink`, {}
            ).then((res) => {
                console.log("SAILINK RESPONSE");
                console.log(res);
                if (this.search_sailink == null || this.search_sailink.length == 0) {
                    this.sailink_data = res.data.data.vessels;
                    this.initTable();
                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Sailink Map
        async fetch_SailinkMap(id) {
            this.mapsailink_data = null;
            this.mapsailink_id = id;
            await api.get(`/api/v1/navplus/sailink/` + id, {}
            ).then((res) => {
                console.log("SHOW ON MAP sailink FETCH");
                console.log(res);
                this.mapsailink_data = res.data.data
                setTimeout(() => {
                    this.initTable_user()
                    this.initTable_tracking(res.data.data)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Sailink Map Date
        async fetch_SailinkMap_date(range) {
            this.last_center = [-1.4312313766684295, 119.1498789812993]
            this.loading_range = true
            this.mapsailink_data_date = null
            this.export_range = range.toLowerCase()
            await api.post(`/api/v1/navplus/sailink/date
            `, {
                id: this.mapsailink_id,
                range: range.toLowerCase()
            }
            ).then((res) => {
                console.log("SHOW ON MAP sailink FETCH DATE");
                console.log(res);
                this.loading_range = false
                this.mapsailink_data_date = res.data.data
                this.moveTracking(res.data.data.tracking)
                this.initTable_tracking(res.data.data)
                console.log("FETCH SAILINK MAP DATE");
                console.log(res.data.data);
                if (res.data.data.maptop != null) {
                    this.last_center = [res.data.data.maptop.lat, res.data.data.maptop.lon]
                    this.sailink_map.setView(new L.LatLng(res.data.data.maptop.lat, res.data.data.maptop.lon), 6);
                } else {
                    this.last_center = [-1.4312313766684295, 119.1498789812993]
                    this.sailink_map.setView(new L.LatLng(-1.4312313766684295, 119.1498789812993), 2);
                }


            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_speedalert(item) {

            this.speedalert_data = null

            await api.get(`/api/v1/navplus/speed/` + item.id, {}
            ).then((res) => {
                console.log("SPEED ALERT RESPONSE");
                console.log(res);

                this.speedalert_data = {
                    emails: '',
                    id: 0,
                    isEnabled: 0,
                    max: 0,
                    min: 0,
                    source: '',
                    vessel_pt: ''
                }

                this.speedalert_data.emails = res.data.data.emails
                this.speedalert_data.id = res.data.data.id
                this.speedalert_data.isEnabled = res.data.data.isEnabled
                this.speedalert_data.max = parseInt(res.data.data.max)
                this.speedalert_data.min = parseInt(res.data.data.min)
                this.speedalert_data.source = res.data.data.source
                this.speedalert_data.vessel_pt = res.data.data.vessel_pt


                if (this.speedalert_data.isEnabled > 0 || this.speedalert_data.isEnabled != null) {
                    $("#speedalert_enable_yes").prop('checked', true);
                    $("#speedalert_enable_no").prop('checked', false);
                } else {
                    $("#speedalert_enable_yes").prop('checked', false);
                    $("#speedalert_enable_no").prop('checked', true);
                }
            }).catch((err) => {

            }).then(() => {
            });

        },
        async fetch_quotaalert(item) {

            this.quotaalert_data = null

            await api.get(`/api/v1/navplus/email/limit/` + item.id, {}
            ).then((res) => {
                console.log("QUOTA ALERT RESPONSE");
                console.log(res);

                this.quotaalert_data = {
                    batas: '',
                    emails: '',
                    enabled: 0,
                    id: 0,
                    isViewOnly: false,
                    name: '',
                    vessel_pt: ''
                }

                this.quotaalert_data.batas = res.data.data.batas
                this.quotaalert_data.emails = res.data.data.emails
                this.quotaalert_data.enabled = res.data.data.enabled
                this.quotaalert_data.id = res.data.data.id
                this.quotaalert_data.isViewOnly = res.data.data.isViewOnly
                this.quotaalert_data.name = res.data.data.name
                this.quotaalert_data.vessel_pt = res.data.data.vessel_pt

                console.log($("#quotaalert_switch"));

                if (this.quotaalert_data.enabled > 0) {
                    $("#quotaalert_switch").prop('checked', true);

                } else {
                    $("#quotaalert_switch").prop('checked', false);

                }
            }).catch((err) => {

            }).then(() => {
            });

        },
        async fetch_vesselname(item) {
            this.changevesselname_data = null

            await api.get(`/api/v1/navplus/vessel/name/` + item.id, {}
            ).then((res) => {
                console.log("CHANGE VESSEL NAME RESPONSE");
                console.log(res);

                this.changevesselname_data = res.data.data
            }).catch((err) => {

            }).then(() => {
            });

        },
        async fetch_ping(item) {
            $('.vsailink_' + item.id).html('<div class="text-center"><div class="spinner-border spinner-border-sm me-2" role="status"></div><div class="spinner-grow spinner-grow-sm me-2" role="status"></div></div>')

            await api.post(`/api/v1/navplus/sailink/fetchPing`, {
                id: item.id,
            }
            ).then((res) => {
                console.log("PING RESPONSE");
                console.log(res);

                setTimeout(() => {
                    $('.vsailink_' + item.id).html(res.data.data.ping)
                }, 100);


            }).catch((err) => {
            }).then(() => {
            });



        },
        async fetch_mrtg(item) {

            await api.get(`/api/v1/navplus/sailink/mrtg/` + item.id + `/` + item.mrtg, {}
            ).then((res) => {
                console.log("MRTG RESPONSE");
                console.log(res);

                this.mrtg_data = res.data.data;

            }).catch((err) => {
            }).then(() => {
            });



        },
        async fetch_weather_dt(id, msgid, type, dateTime) {



            await api.post(`/api/v1/navplus/weather/fetchRow`, {
                id: id,
                msg_id: msgid,
                type: type,
            }
            ).then((res) => {
                console.log("WEATHER DETAIL RESPONSE");
                console.log(res);

                setTimeout(() => {
                    $('#wd' + msgid).html('<span title="The direction from which it originates, measured in degrees clockwise from due north (at 10m).">' + res.data.data.windDirection + '</span>')
                    $('#wd_img' + msgid).html('<img style="height:1em;width:1em" src="' + require('@/assets/weather/wind/' + res.data.data.windIcon) + '"/>')
                    $('#ws' + msgid).html(res.data.data.windSpeed);
                    $('#tmp' + msgid).html(res.data.data.temperature);
                    $('#hmd' + msgid).html(res.data.data.humidity);
                    $('#dp' + msgid).html(res.data.data.dewPoint);
                    $('#wh' + msgid).html(res.data.data.waveSignificantHeight);
                    $('#wfd' + msgid).html('<span title="The direction of the combined wind waves and swells are moving in.">' + res.data.data.waveFromDirection + '</span>');
                    if (res.data.data.waveFromIcon != '-') {
                        $('#wfd_img' + msgid).html('<img style="height:1em;width:1em" src="' + require('@/assets/weather/wind/' + res.data.data.waveFromIcon) + '"/>')
                    }

                }, 100);


            }).catch((err) => {
            }).then(() => {
            });


        },
        async fetchIP(id) {
            await api.get("/api/v1/navplus/sailink/" + id + "/fetchIp", {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            }
            ).then((res) => {
                console.log("IP RESPONSE");
                console.log(res);
                this.ip_addr = res.data.data;
            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetchLinkStatus(id) {
            this.ln_status = null

            await api.get("/api/v1/navplus/sailink/" + id + "/fetchStatus", {}
            ).then((res) => {
                console.log("LINK STATUS RESPONSE");
                console.log(res);
                this.ln_status = res.data.data;
            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetchQuota(service) {
            console.log(service);

            this.quotaData = null
            this.quotaTitle = ''
            var type_char = 'bar'

            if (this.quotaChart) {
                this.quotaChart.destroy()
            }

            if (service.toLowerCase() == 'unmetered') {
                this.quotaTitle = 'Unmetered Bandwidth Usage'
                type_char = 'line'
            } else {
                this.quotaTitle = 'Metered Quota Dialy Usage'
                type_char = 'bar'
            }
            var data = {
                vessel_id: this.mapsailink_data.id,
                service: service,
            };
            console.log(data);
            await api.get(`/api/v1/navplus/sailink/fetchNfaMonthly/` +
                data.vessel_id +
                "/" +
                data.service +
                "", {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            }
            ).then((res) => {
                console.log(res)
                this.quotaData = res.data.data;
                var datasets;
                var startTime = '';
                var endTime = '';
                var unit = ' - '
                const data = [
                    { year: '', count: 0 },
                    { year: '', count: 0 },
                    { year: '', count: 0 },
                    { year: '', count: 0 },

                ];
                var chartLabel = data.map(row => row.year);

                console.log(res.data.data.chart);

                if (res.data.data && res.data.data.isError == false) {
                    datasets = res.data.data.chart.datasets
                    startTime = res.data.data.chart.startTime
                    chartLabel = res.data.data.chart.labels
                    endTime = res.data.data.chart.endTime
                    unit = res.data.data.chart.unit
                } else {
                    datasets = data
                }

                this.quotaChart = new Chart(
                    document.getElementById('quota_chart'),
                    {
                        type: type_char,
                        data: {
                            // labels: data.map(row => row.year),
                            // labels: res.data.data.chart.labels,
                            labels: chartLabel,
                            datasets: datasets
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: "top",
                                },
                                title: {
                                    display: true,
                                    text:
                                        "Daily ( " +
                                        startTime +
                                        " - " +
                                        endTime +
                                        " )",
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {

                                            let label = ' ' + context.dataset.label + ' : ' + context.parsed.y.toFixed(2) + ' ' + unit;
                                            return label;
                                        }
                                    },
                                },
                            },
                            scales: {
                                y: {

                                    title: {
                                        display: true,
                                        font: {
                                            weight: 'bold'
                                        },
                                        text: 'Traffic (' + unit + ')',
                                        test: 'Berhasil',
                                    },
                                    stacked: true,
                                    ticks: {


                                        callback: function (value, index, values) {

                                            return value + ' ' + unit;
                                        }
                                    },
                                },
                            }
                        }
                    }
                );
            }).catch((err) => {
            }).then(() => {
            });




        },
        //------------------------------------------------------------------



        // POST DATA FUNCTIONS
        //------------------------------------------------------------------        
        async update_vesselname() {
            this.loading_changename = true


            await api.post(`/api/v1/navplus/vessel/name`, {
                id: this.change_vesselid,
                name: this.change_vesselname,
            }
            ).then((res) => {
                console.log("VESSEL CHANGE NAME RESPONSE");
                console.log(res);

                this.loading_changename = false


                if (res.status == 200) {
                    $('#sailinkNameModal_alert').removeClass('d-none')
                    $('#sailinkNameModal_alert').addClass('d-block')
                    $('#sailinkNameModal_alert').html('Vessel name changed!')

                    setTimeout(() => {
                        $('#sailinkNameModal_alert').removeClass('d-block')
                        $('#sailinkNameModal_alert').addClass('d-none')
                        $('#sailinkNameModal_alert').html('')
                    }, 1500);

                    this.changevesselname_data = res.data.data
                    this.fetch_sailink();
                } else {
                    $('#sailinkNameModal_alert').removeClass('alert-success')
                    $('#sailinkNameModal_alert').addClass('alert-danger')
                    $('#sailinkNameModal_alert').removeClass('d-none')
                    $('#sailinkNameModal_alert').addClass('d-block')
                    $('#sailinkNameModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#sailinkNameModal_alert').addClass('alert-success')
                        $('#sailinkNameModal_alert').removeClass('alert-danger')
                        $('#sailinkNameModal_alert').removeClass('d-block')
                        $('#sailinkNameModal_alert').addClass('d-none')
                        $('#sailinkNameModal_alert').html('')
                    }, 1500);
                }






            }).catch((err) => {
                $('#sailinkNameModal_alert').removeClass('alert-success')
                $('#sailinkNameModal_alert').addClass('alert-danger')
                $('#sailinkNameModal_alert').removeClass('d-none')
                $('#sailinkNameModal_alert').addClass('d-block')
                $('#sailinkNameModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#sailinkNameModal_alert').addClass('alert-success')
                    $('#sailinkNameModal_alert').removeClass('alert-danger')
                    $('#sailinkNameModal_alert').removeClass('d-block')
                    $('#sailinkNameModal_alert').addClass('d-none')
                    $('#sailinkNameModal_alert').html('')
                }, 1500);
            }).then(() => {
            });

        },
        async update_speedalert() {
            console.log("VESSEL SPEED ALERT CHANGE RESPONSE");


            await api.post("/api/v1/navplus/speed/" + this.speedalert_data.id, {
                isEnabled: this.speedalert_data.isEnabled ? this.speedalert_data.isEnabled : 0,
                speed_min: this.speedalert_data.min,
                speed_max: this.speedalert_data.max,
                emails: this.speedalert_data.emails,
            }

            ).then((res) => {
                console.log("VESSEL SPEED ALERT CHANGE RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#sailinkspeedNotifModal_alert').removeClass('d-none')
                    $('#sailinkspeedNotifModal_alert').addClass('d-block')
                    $('#sailinkspeedNotifModal_alert').html('Successfully Updated Speed Notification Configuration!')

                    setTimeout(() => {
                        $('#sailinkspeedNotifModal_alert').removeClass('d-block')
                        $('#sailinkspeedNotifModal_alert').addClass('d-none')
                        $('#sailinkspeedNotifModal_alert').html('')
                    }, 1500);

                } else {
                    $('#sailinkspeedNotifModal_alert').removeClass('alert-success')
                    $('#sailinkspeedNotifModal_alert').addClass('alert-danger')
                    $('#sailinkspeedNotifModal_alert').removeClass('d-none')
                    $('#sailinkspeedNotifModal_alert').addClass('d-block')
                    $('#sailinkspeedNotifModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#sailinkspeedNotifModal_alert').addClass('alert-success')
                        $('#sailinkspeedNotifModal_alert').removeClass('alert-danger')
                        $('#sailinkspeedNotifModal_alert').removeClass('d-block')
                        $('#sailinkspeedNotifModal_alert').addClass('d-none')
                        $('#sailinkspeedNotifModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#sailinkspeedNotifModal_alert').removeClass('alert-success')
                $('#sailinkspeedNotifModal_alert').addClass('alert-danger')
                $('#sailinkspeedNotifModal_alert').removeClass('d-none')
                $('#sailinkspeedNotifModal_alert').addClass('d-block')
                $('#sailinkspeedNotifModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#sailinkspeedNotifModal_alert').addClass('alert-success')
                    $('#sailinkspeedNotifModal_alert').removeClass('alert-danger')
                    $('#sailinkspeedNotifModal_alert').removeClass('d-block')
                    $('#sailinkspeedNotifModal_alert').addClass('d-none')
                    $('#sailinkspeedNotifModal_alert').html('')
                }, 1500);
            }).then(() => {
            });

        },
        async update_quotaalert() {
            console.log("QUOTA ALERT CHANGE RESPONSE");

            // var _data = {
            //        id: this.quotaalert_data.id,
            //        enabled: this.quotaalert_data.enabled,
            //        batas: this.quotaalert_data.batas,
            //        emails: this.quotaalert_data.emails,
            // }


            console.log(_data);


            await api.post("/api/v1/navplus/email/limit/" + this.quotaalert_data.id, {
                enabled: this.quotaalert_data.enabled,
                batas: this.quotaalert_data.batas,
                emails: this.quotaalert_data.emails,
            }

            ).then((res) => {
                console.log("QUOTA ALERT CHANGE RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#sailinkquotaNotifModal_alert').removeClass('d-none')
                    $('#sailinkquotaNotifModal_alert').addClass('d-block')
                    $('#sailinkquotaNotifModal_alert').html('Successfully Updated Quota Notification Configuration!')

                    setTimeout(() => {
                        $('#sailinkquotaNotifModal_alert').removeClass('d-block')
                        $('#sailinkquotaNotifModal_alert').addClass('d-none')
                        $('#sailinkquotaNotifModal_alert').html('')
                    }, 1500);

                } else {
                    $('#sailinkquotaNotifModal_alert').removeClass('alert-success')
                    $('#sailinkquotaNotifModal_alert').addClass('alert-danger')
                    $('#sailinkquotaNotifModal_alert').removeClass('d-none')
                    $('#sailinkquotaNotifModal_alert').addClass('d-block')
                    $('#sailinkquotaNotifModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#sailinkquotaNotifModal_alert').addClass('alert-success')
                        $('#sailinkquotaNotifModal_alert').removeClass('alert-danger')
                        $('#sailinkquotaNotifModal_alert').removeClass('d-block')
                        $('#sailinkquotaNotifModal_alert').addClass('d-none')
                        $('#sailinkquotaNotifModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#sailinkquotaNotifModal_alert').removeClass('alert-success')
                $('#sailinkquotaNotifModal_alert').addClass('alert-danger')
                $('#sailinkquotaNotifModal_alert').removeClass('d-none')
                $('#sailinkquotaNotifModal_alert').addClass('d-block')
                $('#sailinkquotaNotifModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#sailinkquotaNotifModal_alert').addClass('alert-success')
                    $('#sailinkquotaNotifModal_alert').removeClass('alert-danger')
                    $('#sailinkquotaNotifModal_alert').removeClass('d-block')
                    $('#sailinkquotaNotifModal_alert').addClass('d-none')
                    $('#sailinkquotaNotifModal_alert').html('')
                }, 1500);
            }).then(() => {
            });

        },

        async postSwitchSatnet() {
            this.switch_true = true
            this.satnetmodal_content = 'Please wait...'

            const datasatnet = {
                ip_addr: this.ip_addr.ip_addr_val,
                iq_series: this.ip_addr.iq_series,
                satnet_id: this.satnet_id,
            };

            console.log(datasatnet);

            const response = await api.post(
                "/api/v1/navplus/sailink/pushSwitchSatnet",
                datasatnet,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            );
            if (response.data.data) {
                this.switch_true = true
                this.satnetmodal_content = response.data.data.output
            }
            console.log("RESPONSE DARI POST SATNET");
            console.log(response);
        },
        //------------------------------------------------------------------  



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search sailink table
        sailinkTable_search() {
            console.log(this.search_sailink)
            if (this.search_sailink != null && this.search_sailink.length > 0) {
                this.sailink_table.search(this.search_sailink).draw();
            }
        },
        //clear sailink table
        clear_sailinkTable_search() {
            this.search_sailink = ''
            this.sailink_table.search(this.search_sailink).draw();
        },
        //clear sailink table tracking
        clear_sailinkTableTrack_search() {
            this.search_sailinktrack = ''
            this.sailinktrack_table.search(this.search_sailinktrack).draw();
        },
        //clear sailink table user
        clear_sailinkTableUser_search() {
            this.search_sailinkuser = ''
            this.sailinkuser_table.search(this.search_sailinkuser).draw();
        },
        //search sailink user table
        userTable_search() {
            this.sailinkuser_table.search(this.search_sailinkuser).draw();
        },
        //search sailink tracking table
        trackTable_search() {
            this.sailinktrack_table.search(this.search_sailinktrack).draw();
        },
        //data shorting sailink table 
        sailinkTable_showof(page) {
            console.log("PAGE : " + page);
            this.sailink_table.page.len(page).draw()
            this.page_off = page;
        },
        //data shorting sailink table 
        sailinkTrackTable_showof(page) {
            console.log("PAGE : " + page);
            this.sailinktrack_table.page.len(page).draw()
            this.page_off_tracking = page;
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        initTable() {
            var root = this
            if (this.sailink_table) {
                console.log("MASUK CLEAR TABLE");
                root.sailink_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.sailink_data);
                this.sailink_table = new DataTable('#sailinkTable', {
                    data: this.sailink_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "4%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },

                        {
                            width: "14.7%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {

                                if (this.authStore.userAllowed && this.authStore.userAllowed.allowed['edit vessel detail']) {
                                    return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            <button id="slname_btn" data-bs-toggle="modal" data-bs-target="#sailinkNameModal"  type="button" class="btn btn-sm btn-primary  rounded-none"><i class="fas fa-cog"></i></button>
                            ${data}
                            
                           `+ (this.sailink_data[meta.row].status.toUpperCase() != 'ACTIVE' ? `<span class="badge rounded-none text-bg-secondary">${this.sailink_data[meta.row].status}</span>` : ``) + `
                            </div>
                            `
                                } else {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                   `+ (this.sailink_data[meta.row].status.toUpperCase() != 'ACTIVE' ? `<span class="badge rounded-none text-bg-secondary">${this.sailink_data[meta.row].status}</span>` : ``) + `
                                    </div>
                                    `
                                }

                            }
                        },
                        {
                            width: "10.7%",
                            data: 'quota', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                if (data.quota) {
                                    return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                           
                            <span class="fn-seven">${data.quota} / ${data.usage}</span>
                               <div class="progress rounded-none" role="progressbar" aria-label="Basic example" aria-valuenow="${data.percentage}" aria-valuemin="0" aria-valuemax="100">
                            <div class="progress-bar" style="width: ${data.percentage}%;background-color:${data.fill}"></div>
                            </div>
                            </div>
                            `
                                } else {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    -
                                    </div>
                                    `
                                }

                            }
                        },
                        {
                            width: "22.7%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },

                        {
                            width: "4.7%",
                            data: 'pt_group', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                if (data != null) {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                                } else {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                -
                                </div>
                                    `
                                }
                            }
                        },
                        {
                            width: "6.7%",
                            data: 'serial_number', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "5.7%",
                            data: 'mrtg', className: 'p-0 no-wrap', render: (data, type, row, meta) => {


                                if (data != null) {
                                    return `
                        
                              <div class="p-1 h-100">
                              <button onclick="sailink_mrtg_click('`+ this.sailink_data[meta.row].id + `','` + this.sailink_data[meta.row].mrtg_id + `')"  data-bs-toggle="modal" data-bs-target="#sailinkmrtgModal"  type="button" class="btn btn-sm btn-success w-100 rounded-none text-uppercase">Mrtg</button>
                              </div>
                              `
                                } else {

                                    if ((this.sailink_data[meta.row].mrtg_id && this.sailink_data[meta.row].mrtg_id_2) && this.sailink_data[meta.row].service.toLowerCase() == 'hybrid') {
                                        return ` <div  class="dropdown">
                                    <button class="btn btn-sm rounded-none btn-success dropdown-toggle" data-bs-toggle="dropdown"
                                     aria-expanded="false">
                                    MRTG
                                    </button>
                                    <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
                                    <li><a class="dropdown-item" onclick="sailink_mrtg_click('`+ this.sailink_data[meta.row].id + `','` + this.sailink_data[meta.row].mrtg_id + `')" href="#" data-bs-toggle="modal" data-bs-target="#sailinkmrtgModal">Metered</a></li>
                                    <li><a class="dropdown-item" onclick="sailink_mrtg_click('`+ this.sailink_data[meta.row].id + `','` + this.sailink_data[meta.row].mrtg_id_2 + `')" href="#" data-bs-toggle="modal" data-bs-target="#sailinkmrtgModal">Unmetered</a></li>
                                    </ul>
                                    </div>`
                                    } else if ((this.sailink_data[meta.row].mrtg_id && !this.sailink_data[meta.row].mrtg_id_2) && this.sailink_data[meta.row].service.toLowerCase() == 'hybrid') {
                                        return `
                                         <div class="p-1 h-100">
                              <button onclick="sailink_mrtg_click('`+ this.sailink_data[meta.row].id + `','` + this.sailink_data[meta.row].mrtg_id + `')" data-bs-toggle="modal" data-bs-target="#sailinkmrtgModal"  type="button" class="btn btn-sm btn-success w-100 rounded-none text-uppercase">Mrtg</button>
                              </div>
                                        `
                                    } else if ((!this.sailink_data[meta.row].mrtg_id && this.sailink_data[meta.row].mrtg_id_2) && this.sailink_data[meta.row].service.toLowerCase() == 'hybrid') {
                                        return `
                                         <div class="p-1 h-100">
                              <button onclick="sailink_mrtg_click('`+ this.sailink_data[meta.row].id + `','` + this.sailink_data[meta.row].mrtg_id_2 + `')" data-bs-toggle="modal" data-bs-target="#sailinkmrtgModal"  type="button" class="btn btn-sm btn-success w-100 rounded-none text-uppercase">Mrtg</button>
                              </div>
                                        `
                                    } else if (this.sailink_data[meta.row].mrtg_id && this.sailink_data[meta.row].service.toLowerCase() == 'metered') {
                                        return `
                                         <div class="p-1 h-100">
                              <button onclick="sailink_mrtg_click('`+ this.sailink_data[meta.row].id + `','` + this.sailink_data[meta.row].mrtg_id + `')" data-bs-toggle="modal" data-bs-target="#sailinkmrtgModal"  type="button" class="btn btn-sm btn-success w-100 rounded-none text-uppercase">Mrtg</button>
                              </div>
                                        `
                                    } else if (this.sailink_data[meta.row].mrtg_id && this.sailink_data[meta.row].service.toLowerCase() == 'unmetered') {
                                        return `
                                         <div class="p-1 h-100">
                              <button onclick="sailink_mrtg_click('`+ this.sailink_data[meta.row].id + `','` + this.sailink_data[meta.row].mrtg_id + `')" data-bs-toggle="modal" data-bs-target="#sailinkmrtgModal"  type="button" class="btn btn-sm btn-success w-100 rounded-none text-uppercase">Mrtg</button>
                              </div>
                                        `
                                    } else {
                                        return ``
                                    }

                                }



                            }
                        },
                        {
                            width: "5.7%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button id="slping_btn" `+ (this.sailink_data[meta.row].status.toUpperCase() == 'DEACTIVATED' ? `disabled` : ``) + `  type="button" class="btn btn-sm btn-dark vsailink_` + data + ` w-100 rounded-none">PING</button>
                              </div>
                              `
                            }
                        },
                        {
                            width: "10.4%",
                            data: 'quota', className: 'p-0 no-wrap', render: (data, type, row, meta) => {

                                if (data.quota) {
                                    return `
                                    <div  class="dropdown">
                                    <button class="btn btn-sm rounded-none btn-dark dropdown-toggle" data-bs-toggle="dropdown"
                                     aria-expanded="false">
                                    <i class="fas fa-wrench me-1"></i>Notification
                                    </button>
                                    <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
                                    <li><a class="dropdown-item" id="quotaalert_btn" href="#" data-bs-toggle="modal" data-bs-target="#sailinkquotaNotifModal">Quota Alert</a></li>
                                    <li><a class="dropdown-item" id="speedalert_btn" href="#" data-bs-toggle="modal" data-bs-target="#sailinkspeedNotifModal">Speed Alert</a></li>
                                    
                                    <li><a class="dropdown-item text-secondary" >↳ History</a></li>
                                    </ul>
                                    </div>        
                                    `
                                } else {
                                    return `
                                    <div  class="dropdown">
                                    <button class="btn btn-sm rounded-none btn-dark dropdown-toggle" data-bs-toggle="dropdown"
                                     aria-expanded="false">
                                    <i class="fas fa-wrench me-1"></i>Notification
                                    </button>
                                    <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
                                    <li><div  class="dropdown-item text-secondary" >Quota Alert</div></li>
                                    <li><a class="dropdown-item" id="speedalert_btn" href="#" data-bs-toggle="modal" data-bs-target="#sailinkspeedNotifModal">Speed Alert</a></li>
                                    
                                    <li><a class="dropdown-item text-secondary" >↳ History</a></li>
                                    </ul>
                                    </div>        
                                    `
                                }

                            }
                        },

                        {
                            width: "10.7%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button onclick="showonmap_sailink('`+ data + `')"  type="button" class="btn btn-sm btn-primary w-100 rounded-none">Show on Map</button>
                              </div>
                              `
                            }
                        },
                    ]
                });

                var mom = this;
                this.sailink_table.on('click', '#slping_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailink_table.row(tr);
                    console.log("PING DI CLICK");
                    console.log(row.data());
                    mom.fetch_ping(row.data())
                });

                this.sailink_table.on('click', '#slname_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailink_table.row(tr);
                    console.log("NAME DI CLICK");
                    console.log(row.data());
                    mom.change_vesselname = null
                    setTimeout(() => {
                        mom.change_vesselname = row.data().name
                        mom.change_vesselid = row.data().id
                    }, 1000);
                    mom.fetch_vesselname(row.data())

                });



                this.sailink_table.on('click', '#speedalert_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailink_table.row(tr);
                    console.log("SPEED ALERT CLICK");
                    console.log(row.data());

                    mom.fetch_speedalert(row.data());

                });


                this.sailink_table.on('click', '#quotaalert_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailink_table.row(tr);
                    console.log("QUOTA ALERT CLICK");
                    console.log(row.data());

                    mom.fetch_quotaalert(row.data());

                });
            }
        },
        initTable_user() {
            var root = this
            console.log("INIT TABLE USER");
            console.log(this.sailinkuser_table);
            console.log("MASUK INIT TABLE USER");
            console.log(this.mapsailink_data.vesselUsers);
            this.sailinkuser_table = new DataTable('#sailinkTableUser', {
                data: this.mapsailink_data.vesselUsers,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 5,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "14.25%",
                        data: 'role_name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "34.25%",
                        data: 'lastLogin', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });

        },
        initTable_tracking(datatracking) {
            console.log("INIT TABLE TRACKING");
            console.log(this.sailinktrack_table);
            console.log("MASUK INIT TABLE TRACKING");
            console.log(datatracking.dataTable);

            console.log("SHOW ON MAP WEATHER STATUS");
            console.log(this.mapsailink_data.weather);

            if (this.mapsailink_data.weather) {
                console.log("PUNYA WEATHER");

                this.th_sailink_track_arr = [
                    "#",
                    "Latitude,Longitude\n(DMS)",
                    "Weather Status",
                    "Heading\n(Degree)",
                    "Speed\n(Knots)",
                    "Distance\n(Nmi)",
                    "RX SNR\n(db)",
                    "RX MODCOD",
                    "TX Power\n(db)",
                    "SatNet",
                    "Date/Time\n(GMT+7)",
                    "Link Status"
                ]

                this.td_sailink_track_arr = [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                ];



                console.log(datatracking.dataTable);


                var mom = this;
                setTimeout(() => {
                    mom.sailinktrack_table = new DataTable('#sailinkTableTrack', {
                        data: datatracking.dataTable,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 10,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "3%",
                                data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100">
                                    ${meta.row + 1}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "14.7%",
                                data: 'lat', className: ' text-center', render: (data, type, row, meta) => {
                                    return `
                                    <div onclick="popup_tracking('`+ meta.row + `')" class="p-0 h-100 link_tracking">
                                    ${datatracking.dataTable[meta.row].lat}, ${datatracking.dataTable[meta.row].lng}\n
                                    (${datatracking.dataTable[meta.row].dms})
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "7.7%",
                                data: 'weather', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data} 
                                        <i id="wtr_btn" class="fas fa-plus-square ms-2 text-success wtr_`+ datatracking.dataTable[meta.row].msg_id + `  btn"></i>
                                        <i id="wtroff_btn" style="display:none" class="fas fa-minus-square ms-2 text-danger wtroff_`+ datatracking.dataTable[meta.row].msg_id + `  btn"></i>
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "7.7%",
                                data: 'heading', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "7.7%",
                                data: 'speed', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "7.7%",
                                data: 'distance', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }


                            },
                            {
                                width: "11.7%",
                                data: 'rx_snr', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }


                            },
                            {
                                width: "9.7%",
                                data: 'modcod', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }
                            },
                            {
                                width: "9.7%",
                                data: 'tx', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `  
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }
                            },
                            {
                                width: "11.7%",
                                data: 'satnet', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `  
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }
                            },
                            {
                                width: "9.7%",
                                data: 'dateTime', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `  
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }
                            },
                            {
                                width: "6.7%",
                                data: 'isActive', className: 'td_nowrap text-center', render: (data, type, row, meta) => {


                                    if (data) {
                                        return `  
                                        <button type="button" disabled class="btn btn-success  rounded-pill btn-sm text-uppercase">Up</button>
                                      `
                                    } else {
                                        return `  
                                        <button type="button" disabled class="btn btn-danger  rounded-pill btn-sm text-uppercase">Down</button>
                                      `
                                    }


                                }
                            },
                        ]
                    });

                    mom.sailinktrack_table.on('click', '#wtr_btn', function (e) {
                        let tr = e.target.closest('tr');
                        let row = mom.sailinktrack_table.row(tr);


                        row.child(mom.weather_detail(row.data())).show();
                        $('.wtroff_' + row.data().msg_id).show()
                        $('.wtr_' + row.data().msg_id).hide()

                        mom.fetch_weather_dt(mom.mapsailink_data.id, row.data().msg_id, 'sailink', row.data().dateTime)

                    });


                    mom.sailinktrack_table.on('click', '#wtroff_btn', function (e) {
                        let tr = e.target.closest('tr');
                        let row = mom.sailinktrack_table.row(tr);

                        row.child.hide();
                        $('.wtroff_' + row.data().msg_id).hide()
                        $('.wtr_' + row.data().msg_id).show()

                    });

                }, 500);




            } else {

                console.log("GA PUNYA WEATHER");
                this.th_sailink_track_arr = [
                    "#",
                    "Latitude,Longitude\n(DMS)",
                    "Heading\n(Degree)",
                    "Speed\n(Knots)",
                    "Distance\n(Nmi)",
                    "RX SNR\n(db)",
                    "RX MODCOD",
                    "TX Power\n(db)",
                    "SatNet",
                    "Date/Time\n(GMT+7)",
                    "Link Status"
                ]

                this.td_sailink_track_arr = [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                ];
                var mom = this;
                setTimeout(() => {
                    this.sailinktrack_table = new DataTable('#sailinkTableTrack', {
                        data: datatracking.dataTable,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 10,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "3%",
                                data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100">
                                    ${meta.row + 1}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "14.7%",
                                data: 'lat', className: ' text-center', render: (data, type, row, meta) => {
                                    return `
                                    <div onclick="popup_tracking('`+ meta.row + `')" class="p-0 h-100 link_tracking">
                                    ${datatracking.dataTable[meta.row].lat}, ${datatracking.dataTable[meta.row].lng}\n
                                    (${datatracking.dataTable[meta.row].dms})
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "7.7%",
                                data: 'heading', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "7.7%",
                                data: 'speed', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "7.7%",
                                data: 'distance', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }


                            },
                            {
                                width: "11.7%",
                                data: 'rx_snr', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }


                            },
                            {
                                width: "9.7%",
                                data: 'modcod', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }
                            },
                            {
                                width: "9.7%",
                                data: 'tx', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `  
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }
                            },
                            {
                                width: "11.7%",
                                data: 'satnet', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `  
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }
                            },
                            {
                                width: "9.7%",
                                data: 'dateTime', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    return `  
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                      `
                                }
                            },
                            {
                                width: "6.7%",
                                data: 'isActive', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                                    if (data) {
                                        return `  
                                        <button type="button" disabled class="btn btn-success  rounded-pill btn-sm text-uppercase">Up</button>
                                      `
                                    } else {
                                        return `  
                                        <button type="button" disabled class="btn btn-danger  rounded-pill btn-sm text-uppercase">Down</button>
                                      `
                                    }
                                }
                            },
                        ]
                    });
                }, 500);



            }





        },
        //------------------------------------------------------------------



        //SHOW ON MAP FUNCTIONS
        //------------------------------------------------------------------
        //show on map sailink
        showonMap(id) {
            console.log("SAILINK SHOW ON MAP");
            this.is_showonmap = false;
            console.log("VESSEL ID: "+id);
            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {

                if (this.eta_store.tableRoutedetail) {
                    this.eta_store.tableRoutedetail.destroy();
                    this.eta_store.tableRoutedetail = null
                }
                this.is_showonmap = true;
                this.mapsailink_data = null;
                this.mapsailink_data_date = null;
                this.mapsailink_id = null;
                this.show_range = "Last 24 Hours";
                this.tracking_layer.clearLayers();
                this.target_layer.clearLayers();
                this.link_layer.clearLayers();
                this.satnet_id = null;
                this.switch_true = false;
                this.satnetmodal_content = 'Satnet will be switch';
                this.satnet_output = null;
                this.ip_addr = null;
                this.fetch_SailinkMap(id);
                this.fetchIP(id)
                this.fetchLinkStatus(id)


                console.log("SHOW  ON MAP STATUS: "+this.is_showonmap);

                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#mapsailink_card").offset().top) - 68
                    }, 500);
                }, 10);
                setTimeout(() => {
                    this.fetch_SailinkMap_date("24");
                }, 1000);
            }, 5);
        },
        //close show on map sailink
        closeonMap() {
            this.is_showonmap = null;
            this.mapsailink_id = null;
            this.mapsailink_data = null;
            this.mapsailink_data_date = null;
            this.show_range = "Last 24 Hours";
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.link_layer.clearLayers();
            $('html, body').animate({ scrollTop: '0px' }, 500);

            if (this.eta_store.tableRoutedetail) {
                this.eta_store.tableRoutedetail.destroy();
                this.eta_store.tableRoutedetail = null
            }
        },
        //------------------------------------------------------------------



        //CHANGE RANGE FUNCTIONS
        //------------------------------------------------------------------

        change_range(index) {
            this.show_range = this.range_arr[index].label;
            this.fetch_SailinkMap_date(this.range_arr[index].val);
            this.link_layer.clearLayers();
        },
        //------------------------------------------------------------------



        //MAP ESSENTIALS FUNCTIONS
        //------------------------------------------------------------------
        initializeMap(map_) {

            console.log("MAP SAILINK INITIALIZED");
            this.sailink_map = map_;
            this.target_layer.addTo(this.sailink_map);
            this.tracking_layer.addTo(this.sailink_map);
            this.link_layer.addTo(this.sailink_map);
            this.search_layer.addTo(this.sailink_map);
            this.sailink_map.scrollWheelZoom.disable();
            var searchLayer = this.search_layer;
            var mymap = this.sailink_map;

            this.eta_store.init_eta(mymap, 'sailink');
            this.eta_store.init_start_eta(mymap, 'sailink', this.mapsailink_data.maptop);
            this.eta_store.init_detail_route(mymap, 'sailink')
            this.eta_store.table_koordinat();



            setTimeout(() => {
                L.Control.geocoder(
                    {
                        defaultMarkGeocode: false

                    }
                ).on('markgeocode', function (e) {
                    console.log(e);
                    console.log(e.geocode.center);
                    console.log(e.geocode.html);
                    console.log("MAP SAILINK INITIALIZED 2");
                    mymap.setView(new L.LatLng(e.geocode.center.lat, e.geocode.center.lng), 8);

                    var circle = L.circle([e.geocode.center.lat, e.geocode.center.lng], {
                        color: "#1DC01D",
                        fillColor: "#1DC01D",
                        fillOpacity: 0.0,
                        opacity: 0.0,
                        weight: 0,
                        radius: 1600,
                    }).bindPopup(
                        e.geocode.html.length > 0 ?
                            e.geocode.html : e.geocode.name
                    )

                    circle.on("add", function (event) {
                        event.target.openPopup();
                    });

                    circle.addTo(searchLayer)
                }).addTo(this.sailink_map);
            }, 500);


        },
        onmap_click() {
            this.search_layer.clearLayers();
        },
        fullscreen_scroll() {
            console.log("CLICK FULL");
            this.sailink_map.invalidateSize();
            if (this.sailink_map.isFullscreen() == true) {
                this.sailink_map.scrollWheelZoom.enable();
            } else {
                this.sailink_map.scrollWheelZoom.disable();
            }
        },
        return_track_icon() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#0000FF" 
                stroke-width="0"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M50.8,56.4L30.6,43.7c-1.3-0.8-3-0.8-4.3,0L6.2,56.4c-3.4,2.2-7.5-1.6-5.7-5.2L24.9,2.2c1.5-3,5.7-3,7.2,0l24.5,48.9
                C58.4,54.8,54.3,58.5,50.8,56.4z" fill="#0000FF"></path>
                </svg>`,
                className: "",
                iconSize: [57 / 3.5, 57 / 3.5],
                iconAnchor: [57 / 3.5 / 2, 57 / 3.5 / 2],
            });
            return svgIcon
        },
        return_icon(maptop) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                    <svg
                    stroke="#FFF" 
                    stroke-width="10"
                    viewBox="0 0 40 100"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="`+ maptop.fill + `"></path>
                    </svg>`,
                className: "",
                iconSize: [40 / 5, 100 / 5],
                iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
            });

            return svgIcon;


        },
        popup_tracking(index) {
            console.log(index);
            this.link_layer.clearLayers();
            var circle = L.circle([this.marker_tracking[index].lat, this.marker_tracking[index].lng], {
                color: "#1DC01D",
                fillColor: "#1DC01D",
                fillOpacity: 0.0,
                opacity: 0.0,
                weight: 0,
                radius: 1600,
            }).bindPopup(
                "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lat +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lng +
                "</td></tr><tr>" +
                "<td><b>DMS</b></td><td>:</td>" +
                "<td>" +
                this.marker_tracking[index].dms +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                this.marker_tracking[index].speed +
                "&nbsp;knots </td>" +
                "</tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].heading +
                "</td>" +
                " </tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].updated +
                "</td>" +
                "</tr>" +
                "</table>"
            )

            circle.on("add", function (event) {
                event.target.openPopup();
            });

            this.sailink_map.setView(new L.LatLng(this.marker_tracking[index].lat, this.marker_tracking[index].lng), 6);

            circle.addTo(this.link_layer)

            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#map_sailink").offset().top) - 68
                    }, 500);
                }, 10);
            }, 10);
        },
        setview_map() {
            this.sailink_map.setView(new L.LatLng(this.mapsailink_data_date.maptop.lat, this.mapsailink_data_date.maptop.lon), 6);
        },
        //------------------------------------------------------------------




        //MOVING MARKER FUNCTIONS
        //-----------------------------------------------------------------
        //fetch tracking 24 hour marker clicked
        moveTracking(data_tracking) {
            clearInterval(this.interval_tracking);
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            console.log("MASUK KE MOVE TRACKING");
            console.log(data_tracking);
            this.marker_tracking = data_tracking;
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.moving_marker = null;

            if (data_tracking.length > 0) {
                this.moving_marker = L.marker([data_tracking[0].lat, data_tracking[0].lng], {
                    icon: this.return_track_icon(),
                    rotationAngle: data_tracking[0].heading,
                }).addTo(this.target_layer);

                var firstpolyline = new L.Polyline(data_tracking, {
                    color: '#FEE800',
                    weight: 6,
                    opacity: 0.5,
                    smoothFactor: 1
                });
                this.tracking_layer.addLayer(firstpolyline);
                var mom = this
                var i = data_tracking.length
                setTimeout(() => {
                    data_tracking.forEach(function (coord) {
                        i--
                        if (i != 0) {
                            if (mom.authStore.userAllowed && !mom.authStore.userAllowed.allowed['hide marker dot']) {
                                var circle = L.circle([coord.lat, coord.lng], {
                                    color: "#1DC01D",
                                    fillColor: "#1DC01D",
                                    fillOpacity: 0.3,
                                    opacity: 0.6,
                                    weight: 2,
                                    radius: 1600,
                                }).bindPopup(
                                    "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                                    coord.lat +
                                    "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                                    coord.lng +
                                    "</td></tr><tr>" +
                                    "<td><b>DMS</b></td><td>:</td>" +
                                    "<td>" +
                                    coord.dms +
                                    "</td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    "<td><b>Speed</b></td>" +
                                    "<td>:</td>" +
                                    " <td>" +
                                    coord.speed +
                                    "&nbsp;knots </td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    " <td><b>Heading</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.heading +
                                    "</td>" +
                                    " </tr>" +
                                    "<tr>" +
                                    "<td><b>Last Update</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.updated +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>"
                                ).addTo(mom.target_layer)

                            }
                        }
                    });
                }, 1600);
                this.initialdata_moving();
                this.movingStart();
            }


        },
        //moving marker
        initialdata_moving() {
            this.tracking_path = this.marker_tracking.slice()
            if (this.tracking_path[0]) {
                this.tracking_latLng = L.latLng([this.tracking_path[0].lat, this.tracking_path[0].lng])
                this.tracking_rotation = this.tracking_path[0].heading
            }

        },
        //moving marker
        movingStart() {
            var mother = this
            this.interval_tracking = setInterval(function () {
                mother.simulate()
            }, 250);
        },
        simulate() {
            if (this.tracking_path && this.tracking_path.length > 0) {
                let point = this.tracking_path.shift()
                this.tracking_latLng = L.latLng([point.lat, point.lng])
                this.tracking_rotation = point.heading
                this.moving_marker.setLatLng(this.tracking_latLng)
                this.moving_marker.slideTo(this.tracking_latLng, {
                    duration: 500,
                });
                this.moving_marker.options.rotationAngle = this.tracking_rotation;
            } else {
                this.target_layer.removeLayer(this.moving_marker);
                clearInterval(this.interval_tracking);
                this.tracking_path = null;
            }

        },
        //-----------------------------------------------------------------


        //UNINIT DATA FUNCTIONS
        //-----------------------------------------------------------------   
        mrtg_click(vessel_id, id) {
            this.mrtg_data = null
            const datamrtg = {
                id: vessel_id,
                mrtg: id,
            };

            this.fetch_mrtg(datamrtg);

        },
        speedenable_change(value, cat) {
            console.log("IS CHECKED RADIO");

            console.log($('#speedalert_enable_yes').prop('checked'));
            console.log($('#speedalert_enable_no').prop('checked'));

            if (cat.toUpperCase() == 'YES') {
                if ($('#speedalert_enable_yes').prop('checked') == true) {
                    $("#speedalert_enable_no").prop('checked', false);

                } else if ($('#speedalert_enable_no').prop('checked') == true) {
                    $("#speedalert_enable_yes").prop('checked', false);
                }
            } else {
                if ($('#speedalert_enable_no').prop('checked') == true) {
                    $("#speedalert_enable_yes").prop('checked', false);

                } else if ($('#speedalert_enable_yes').prop('checked') == true) {
                    $("#speedalert_enable_no").prop('checked', false);
                }
            }




            this.speedalert_data.isEnabled = value

            console.log(this.speedalert_data.isEnabled);


            // this.speedalert_data.isEnabled = value

            // if(this.speedalert_data.isEnabled > 0 && cat.toUpperCase() == 'YES'){

            // }


            // if (value>0) {
            //     $("#speedalert_enable_yes").prop('checked', true);
            //     $("#speedalert_enable_no").prop('checked', false);
            // } else {
            //     $("#speedalert_enable_yes").prop('checked', false);
            //     $("#speedalert_enable_no").prop('checked', true);
            // }
        },
        weather_detail(d) {

            return (
                '<div class="bg-white p-2 border">' +

                '<div class="modal-header mt-2">' +
                '<h6 class="">Weather & Wave</h6>' +
                '</div>' +

                '<div class="row">' +
                '<div class="col">' +
                '<table class="table table-striped">' +
                '<tr>' +
                '<td>' +
                'Weather' +
                '</td>' +
                '<td>' +
                d.weather +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Wind Direction' +
                '</td>' +
                '<td class="no-wrap" >' +
                '<div class="row">' +
                '<div class="col-sm-1" id="wd_img' + d.msg_id + '" >' +
                '</div>' +
                '<div id="wd' + d.msg_id + '" class="col text-start link_wd">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</div>' +
                '</div>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Wind Speed' +
                '</td>' +
                '<td id="ws' + d.msg_id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '</table>' +
                '</div>' +

                '<div class="col">' +
                '<table class="table table-striped">' +
                '<tr>' +
                '<td>' +
                'Temperature' +
                '</td>' +
                '<td id="tmp' + d.msg_id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Humidity' +
                '</td>' +
                '<td id="hmd' + d.msg_id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Dew Point' +
                '</td>' +
                '<td id="dp' + d.msg_id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '</table>' +
                '</div>' +


                '<div class="col">' +
                '<table class="table table-striped">' +
                '<tr>' +
                '<td>' +
                'Wave Height' +
                '</td>' +
                '<td id="wh' + d.msg_id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Wave From Direction' +
                '</td>' +
                '<td >' +
                '<div class="row">' +
                '<div class="col-sm-1" id="wfd_img' + d.msg_id + '" >' +
                '</div>' +
                '<div id="wfd' + d.msg_id + '" class="col text-start link_wd">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</div>' +
                '</div>' +
                '</td>' +
                '</tr>' +
                '</table>' +
                '</div>' +
                '</div>' +
                '</div>'
            );
        },
        toggle_satnet(id, variable, type) {
            console.log(id);
            var status = $(id)[0].checked;
            if (type == 'subsatnet') {
                console.log('subsatnet');
                if (!this.EIRP && !this.GNT && status == true) {
                    $('#eirp_sailink_switch')[0].checked = true;
                    this.EIRP = status
                }
                console.log(status);
                this[variable] = status
            } else {
                console.log('mainsatnet');
                $(id)[0].checked = status
                this[variable] = status
            }

        },
        clearSatnetId() {
            this.satnet_id = null
        },
        scroll_top() {
            $('html, body').animate({ scrollTop: '0px' }, 500);
        },
        //-----------------------------------------------------------------  



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            this.sailink_table=null;
            this.sailinkuser_table=null;
            this.sailinktrack_table=null;
            this.is_showonmap=null;
            this.search_sailink=null;
            this.search_sailinkuser=null;
            this.search_sailinktrack=null;
            this.quotaData=null;
            this.quotaTitle='';
            this.sailink_data=null;
            this.ip_addr=null;
            this.ln_status=null;
            this.last_center=[-1.4312313766684295, 119.1498789812993];
            this.last_zoom=6;
            this.mapsailink_data=null;
            this.mapsailink_data_date=null;
            this.page_off=10;
            this.satnet_id=null;
            this.switch_true=false;
            this.satnetmodal_content='Satnet will be switch';
            this.satnet_output=null;
            this.page_off_tracking=10;
            this.show_range="Last 24 Hours";
            this.export_range="24";
            this.mapsailink_id=null;
            this.loading_range=false;
            this.tracking_layer=L.layerGroup();
            this.target_layer=L.layerGroup();
            this.link_layer=L.layerGroup();
            this.search_layer=L.layerGroup();
            this.marker_tracking=[];
            this.moving_marker=null;
            this.tracking_path=null;
            this.tracking_latLng=null;
            this.tracking_rotation=null;
            this.interval_tracking=null;
            this.sailink_map=null;
            this.date_range=null;
            this.isBottom=false;
            this.quotaChart=null;
            this.change_vesselname=null;
            this.changevesselname_data=null;
            this.change_vesselid=null;
            this.loading_changename=false;
            this.mrtg_data=null;
            this.mrtg_title={
                1: "Hourly (1 Minute Average)",
                2: "Daily (5 Minute Average)",
                3: "Weekly (30 Minute Average)",
                4: "Monthly (2 Hour Average)",
                5: "Yearly (1 Day Average)",
            };
            this.speedalert_data=null;
            this.quotaalert_data=null;
            this.range_arr=[
                { label: "Today", val: "today" },
                { label: "Last 24 Hours", val: "24" },
                { label: "Last 7 Days", val: "7" },
                { label: "Last 30 Days", val: "30" },
                { label: "This Week", val: "week" },
                { label: "This Month", val: "month" },
                { label: "Last Month", val: "lmonth" },
                { label: "Custom Range", val: "24" },
            ];
            this.page_off_arr=[
                10,
                25,
                50,
                100
            ];
            this.th_sailink_arr=[
                "No",
                "Vessel Name",
                "Quota",
                "Company",
                "Group",
                "S/N",
                "",
                "",
                "",
                ""
            ];
            this.th_sailink_track_arr=[
                "#",
                "Latitude,Longitude\n(DMS)",
                "Heading\n(Degree)",
                "Speed\n(Knots)",
                "Distance\n(Nmi)",
                "RX SNR\n(db)",
                "RX MODCOD",
                "TX Power\n(db)",
                "SatNet",
                "Date/Time\n(GMT+7)",
                "Link Status"
            ];
            this.th_sailink_user_arr=[
                "#",
                "Name",
                "Email",
                "Role",
                "Last Login"
            ];
            this.td_button=[
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'button_mrtg', b: 'btn-success' },
                { a: 'button_ping', b: 'btn-secondary' },
                { a: 'button_notif', b: 'btn-secondary' },
                { a: 'button_showonmap', b: 'btn-primary' }
            ];
            this.td_sailink_arr=[
                4,
                14.7,
                7.7,
                16.7,
                6.7,
                6.7,
                7.7,
                7.7,
                10.4,
                13.7,
            ];
            this.td_sailink_track_arr=[
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];
    
            //SATNET
            //Skyreach 1
            this.path_cns11=cns11;
            this.gain_cns11=cns11gain;
            this.path_cns11gnt=cns11_gnt;
            this.gain_cns11gnt=cns11_gntgain;
            //Skyreach 2
            this.path_apstar5=apstar5;
            this.gain_apstar5=apstar5gain;
            this.path_apstar5gnt=apstar5_gnt;
            this.gain_apstar5gnt=apstar5_gntgain;
    
            //Apstar 9
            this.path_apstar9=apstar92;
            this.gain_apstar9=apstar92gain;
            this.path_apstar9gnt=apstar92_gnt;
            this.gain_apstar9gnt=apstar92_gntgain;
    
            //ABS 2A
            this.path_abs=abs;
            this.gain_abs=absgain;
            this.path_absgnt=abs_gnt;
            this.gain_absgnt=abs_gntgain;
    
            //SR1 Maritime
            this.path_ocn=ocn;
            this.gain_ocn=ocngain;
            this.path_ocngnt=ocn_gnt;
            this.gain_ocngnt=ocn_gntgain;
    
            //SR2 HTS BEAM#4
            this.path_beam4=hts;
            this.gain_beam4=htsgain;
            this.path_beam4gnt=hts_gnt;
            this.gain_beam4gnt=hts_gntgain;
    
            //SR2 HTS BEAM#3
            this.path_beam3=beam3;
            this.gain_beam3=beam3gain;
            this.path_beam3gnt=beam3_gnt;
            this.gain_beam3gnt=beam3_gntgain;

            //Horizon
            this.path_hrz=horizon;
            this.gain_hrz=horizongain;
            this.path_hrzgnt=horizon_gnt;
            this.gain_hrzgnt=horizon_gntgain;
            //Utelsat
            this.path_utls=utelsat;
            this.gain_utls=utelsatgain;
            this.path_utlsgnt=utelsat_gnt;
            this.gain_utlsgnt=utelsat_gntgain;

            this.EIRP=false;
            this.GNT=false;
            this.SKYR1=false;
            this.SKYR2=false;
            this.APST9=false;
            this.ABS2A=false;
            this.SR1MR=false;
            this.BEAM4=false;
            this.BEAM3=false;
            this.HRZ=false;
            this.UTLS=false;
            this.polygonColor={
                skyr1: "green",
                skyr2: "blue",
                apst9: "purple",
                abs2a: "orange",
                sr1mr: "#F7038B",
                beam4: "#DB6B0F",
                beam3: "#DB6B0F",
                hrz: "#DBC70f",
                utls: "#0CAD93"
            };
        }
        //------------------------------------------------------------------

    }
})
