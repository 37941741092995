import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';



export const useAdminStore = defineStore({
    id: 'admin',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        authStore: useAuthStore(),
        admin_table: null,
        search_admin: null,
        admin_data: null,
        page_off: 10,
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        th_admin_arr: [
            "No",
            "Name",
            "Email",
            "Username",
            "Role",
            "Group",
            "Enabled",
            "Profile",
            "Permission",
            "Company/Vessel",
        ],

        td_admin_arr: [
            11.7,
            11.7,
            11.7,
            11.7,
            11.7,
            11.7,
            11.7,
            6,
            6,
            6,
        ],

        td_button: [
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'button_show', b: 'btn-primary' },
            { a: 'button_show', b: 'btn-primary' },
            { a: 'button_show', b: 'btn-primary' },
        ],



        admin_createuser:
        {
            name: "",
            username: "",
            email: "",
            vessels: [],
            password: "",
            confirmpass: "",
        },

        admin_createowner: {
            name: "",
            username: "",
            email: "",
            role: "3",
            pts: [],
            password: "",
            password_confirmation: "",
        },

        admin_updatepass:
        {
            new_password: "",
            confirmpass: "",
        },

        admin_updatepass_pass_error: false,
        admin_updatepass_item: null,

        admin_createuser_owner_email: null,
        admin_createuser_owner: null,
        admin_createuser_owner_label: "Select Ship Owner",
        admin_createuser_vessel: null,
        admin_createuser_email_error: false,
        admin_createuser_pass_error: false,

        admin_createowner_company: null,
        admin_createowner_email_error: false,
        admin_createowner_pass_error: false,

        admin_updatecompany_item: null,
        admin_updatecompany_company: null,
        admin_updatecompany_owned: [],
        admin_updatecompany_owned_arr: [],

        admin_updatepermis_item: null,
        admin_updatepermis_permis: null,
        admin_updatepermis_permissions_fitur: null,
        admin_updatepermis_allowed: [],
        admin_updatepermis_allowed_arr: [],

        admin_updateprofile: {
            name: "",
            username: "",
            email: "",
        },
        admin_updateprofile_data: null,
        admin_updateprofile_item: null,

        admin_updateapi: {
            passHash: "",
            ip_whitelist: "",
            isEnabled: false,
        },
        admin_updateapi_item: null,
        admin_updateapi_show: false,
        admin_updateapi_data: null,

        passhow: false,
        con_passhow: false,

        up_passhow: false,
        up_con_passhow: false,

        owner_passhow: false,
        owner_con_passhow: false,

    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Admin Data
        async fetch_admin() {
            await api.get(`/api/v1/navplus/user`, {}
            ).then((res) => {
                console.log("ADMIN RESPONSE");
                console.log(res.data.data.users);
                if (this.search_admin == null || this.search_admin.length == 0) {
                    this.admin_data = res.data.data.users;
                    this.initTable();
                }

            }).catch((err) => {
            }).then(() => {
            });
        },

        //Fetch Create User
        async fetch_create_user() {
            this.admin_createuser = {
                name: "",
                username: "",
                email: "",
                vessels: [],
                password: "",
                confirmpass: "",
            },
                this.admin_createuser_owner = null,
                this.admin_createuser_vessel = null,
                this.admin_createuser_owner_label = "Select Ship Owner",

                console.log("FETCH CREATE USER");
            await api.get(`/api/v1/navplus/user/createUser`, {}
            ).then((res) => {
                console.log("FETCH CREATE USER RESPONSE");
                console.log(res.data.data.owners);
                this.admin_createuser_owner = res.data.data.owners

            }).catch((err) => {
            }).then(() => {
            });
        },

        //Fetch Create Owner
        async fetch_create_owner() {
            this.admin_createowner = {
                name: "",
                username: "",
                email: "",
                role: "3",
                pts: [],
                password: "",
                password_confirmation: "",
            },
                this.admin_createowner_company = null


            console.log("FETCH CREATE OWNER");
            await api.get(`/api/v1/navplus/user/createOwner`, {}
            ).then((res) => {
                console.log("FETCH CREATE OWNER RESPONSE");
                console.log(res.data.data);
                this.admin_createowner_company = res.data.data.pts


            }).catch((err) => {
            }).then(() => {
            });
        },

        //Fetch Edit Profile
        async fetch_edit_profile(id) {
            this.admin_updateprofile = {
                name: "",
                username: "",
                email: "",
            },
                this.admin_updateprofile_data = null,

                console.log("FETCH UPDATE PROFILE");
            await api.get("/api/v1/navplus/user/edit/" + id, {}
            ).then((res) => {
                console.log("FETCH UPDATE PROFILE RESPONSE");
                console.log(res.data.data);
                this.admin_updateprofile_data = res.data.data
                this.admin_updateprofile.name = res.data.data.user.name
                this.admin_updateprofile.username = res.data.data.user.username
                this.admin_updateprofile.email = res.data.data.user.email
            }).catch((err) => {
            }).then(() => {
            });
        },

        //Fetch Edit API
        async fetch_api_data(id) {

            this.admin_updateapi = {
                passHash: "",
                ip_whitelist: "",
                isEnabled: false,
            },
                this.admin_updateapi_data = null
            this.admin_updateapi_show = false

            console.log("FETCH API DATA");
            await api.get("/api/v1/navplus/user/apiAccess/" + id, {}
            ).then((res) => {
                console.log("FETCH  API DATA RESPONSE");
                console.log(res.data.data);

                this.admin_updateapi = {
                    passHash: res.data.data.passHash,
                    ip_whitelist: res.data.data.ip_whitelist,
                    isEnabled: res.data.data.isEnabled,
                },
                    this.admin_updateapi_data = res.data.data
            }).catch((err) => {
            }).then(() => {
            });
        },

        //Fetch Company
        async fetch_company(id) {
            console.log("FETCH COMPANY");
            console.log(id)
            this.admin_updatecompany_company = null
            this.admin_updatecompany_owned = []
            this.admin_updatecompany_owned_arr = []
            await api.get(`/api/v1/navplus/user/edit/vessels/` + id, {}
            ).then((res) => {
                console.log("FETCH COMPANY RESPONSE");
                console.log(res.data.data);
                this.admin_updatecompany_company = res.data.data.items
                this.admin_updatecompany_owned = res.data.data.owned
                this.admin_updatecompany_owned_arr = res.data.data.owned
            }).catch((err) => {
            }).then(() => {
            });
        },

        //Fetch Permissions
        async fetch_permis(id) {
            console.log("FETCH PERMISSIONS");
            console.log(id)
            this.admin_updatepermis_permissions_fitur = null
            this.admin_updatepermis_allowed = []
            this.admin_updatepermis_allowed_arr = []
            await api.get(`/api/v1/navplus/user/edit/permission/` + id, {}
            ).then((res) => {
                console.log("FETCH PERMISSIONS RESPONSE");
                console.log(res.data.data);
                this.admin_updatepermis_permissions_fitur = res.data.data.permissions_fitur
                this.admin_updatepermis_allowed = res.data.data.allowed
                this.admin_updatepermis_allowed_arr = res.data.data.allowed
            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //DATA POST FUNCTIONS
        //------------------------------------------------------------------
        //Post Create User
        async post_create_user() {

            console.log("POST CREATE USER");

            var owner_;
            if (this.authStore.user.user.role_id != 3) {
                owner_ = this.admin_createuser_owner_email
            } else {
                owner_ = this.authStore.user.user.email
            }

            var data_ = {
                name: this.admin_createuser.name,
                username: this.admin_createuser.username,
                email: this.admin_createuser.email,
                password: this.admin_createuser.password,
                role: "4",
                vessels: this.admin_createuser.vessels,
                owner: owner_,
                password_confirmation: this.admin_createuser.confirmpass,
            }

            console.log("POST CREATE USER LOGS");

            console.log(data_);



            await api.get(`/api/v1/navplus/user/register`, {

                name: this.admin_createuser.name,
                username: this.admin_createuser.username,
                email: this.admin_createuser.email,
                password: this.admin_createuser.password,
                role: "4",
                vessels: this.admin_createuser.vessels,
                owner: owner_,
                password_confirmation: this.admin_createuser.confirmpass,

            }
            ).then((res) => {
                console.log("POST CREATE USER RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#createUserModal_alert').removeClass('d-none')
                    $('#createUserModal_alert').addClass('d-block')
                    $('#createUserModal_alert').html('New user created!')

                    setTimeout(() => {
                        $('#createUserModal_alert').removeClass('d-block')
                        $('#createUserModal_alert').addClass('d-none')
                        $('#createUserModal_alert').html('')
                    }, 1500);

                    this.admin_createuser = {
                        name: "",
                        username: "",
                        email: "",
                        vessels: [],
                        password: "",
                        confirmpass: "",
                    },
                        this.admin_createuser_vessel = null;
                    this.admin_createuser_owner_email = null;
                    this.admin_createuser_owner_label = "Select Ship Owner";
                    this.fetch_admin();

                } else {
                    $('#createUserModal_alert').removeClass('alert-success')
                    $('#createUserModal_alert').addClass('alert-danger')
                    $('#createUserModal_alert').removeClass('d-none')
                    $('#createUserModal_alert').addClass('d-block')
                    $('#createUserModal_alert').html('Something wrong, cannot create!')

                    setTimeout(() => {
                        $('#createUserModal_alert').addClass('alert-success')
                        $('#createUserModal_alert').removeClass('alert-danger')
                        $('#createUserModal_alert').removeClass('d-block')
                        $('#createUserModal_alert').addClass('d-none')
                        $('#createUserModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#createUserModal_alert').removeClass('alert-success')
                $('#createUserModal_alert').addClass('alert-danger')
                $('#createUserModal_alert').removeClass('d-none')
                $('#createUserModal_alert').addClass('d-block')
                $('#createUserModal_alert').html('Something wrong, cannot create!')

                setTimeout(() => {
                    $('#createUserModal_alert').addClass('alert-success')
                    $('#createUserModal_alert').removeClass('alert-danger')
                    $('#createUserModal_alert').removeClass('d-block')
                    $('#createUserModal_alert').addClass('d-none')
                    $('#createUserModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post Create Owner
        async post_create_owner() {

            console.log("POST CREATE OWNER");
            console.log("POST CREATE USER LOGS");

            await api.get(`/api/v1/navplus/user/register`, {

                name: this.admin_createowner.name,
                username: this.admin_createowner.username,
                email: this.admin_createowner.email,
                role: "3",
                pts: this.admin_createowner.pts,
                password: this.admin_createowner.password,
                password_confirmation: this.admin_createowner.password_confirmation,

            }
            ).then((res) => {
                console.log("POST CREATE OWNER RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#createOwnerModal_alert').removeClass('d-none')
                    $('#createOwnerModal_alert').addClass('d-block')
                    $('#createOwnerModal_alert').html('New owner created!')

                    setTimeout(() => {
                        $('#createOwnerModal_alert').removeClass('d-block')
                        $('#createOwnerModal_alert').addClass('d-none')
                        $('#createOwnerModal_alert').html('')
                    }, 1500);

                    this.admin_createowner = {
                        name: "",
                        username: "",
                        email: "",
                        role: "3",
                        pts: [],
                        password: "",
                        password_confirmation: "",
                    },
                        this.admin_createowner_company = null

                    this.fetch_admin();

                } else {
                    $('#createOwnerModal_alert').removeClass('alert-success')
                    $('#createOwnerModal_alert').addClass('alert-danger')
                    $('#createOwnerModal_alert').removeClass('d-none')
                    $('#createOwnerModal_alert').addClass('d-block')
                    $('#createOwnerModal_alert').html('Something wrong, cannot create!')

                    setTimeout(() => {
                        $('#createOwnerModal_alert').addClass('alert-success')
                        $('#createOwnerModal_alert').removeClass('alert-danger')
                        $('#createOwnerModal_alert').removeClass('d-block')
                        $('#createOwnerModal_alert').addClass('d-none')
                        $('#createOwnerModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#createOwnerModal_alert').removeClass('alert-success')
                $('#createOwnerModal_alert').addClass('alert-danger')
                $('#createOwnerModal_alert').removeClass('d-none')
                $('#createOwnerModal_alert').addClass('d-block')
                $('#createOwnerModal_alert').html('Something wrong, cannot create!')

                setTimeout(() => {
                    $('#createOwnerModal_alert').addClass('alert-success')
                    $('#createOwnerModal_alert').removeClass('alert-danger')
                    $('#createOwnerModal_alert').removeClass('d-block')
                    $('#createOwnerModal_alert').addClass('d-none')
                    $('#createOwnerModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post Company
        async post_company() {
            console.log("POST UPDATE COMPANY");
            await api.get(`/api/v1/navplus/user/edit/vessels/` + this.admin_updatecompany_item.id, {
                role: this.admin_updatecompany_item.role,
                items: this.admin_updatecompany_owned_arr,
            }
            ).then((res) => {
                console.log("POST UPDATE COMPANY");
                console.log(res);

                if (res.status == 200) {
                    $('#updateCompanyModal_alert').removeClass('d-none')
                    $('#updateCompanyModal_alert').addClass('d-block')
                    $('#updateCompanyModal_alert').html('Company update!')

                    setTimeout(() => {
                        $('#updateCompanyModal_alert').removeClass('d-block')
                        $('#updateCompanyModal_alert').addClass('d-none')
                        $('#updateCompanyModal_alert').html('')
                    }, 1500);

                    this.admin_updatecompany_company = null
                    this.admin_updatecompany_owned = []
                    this.admin_updatecompany_owned_arr = []

                    this.fetch_company(this.admin_updatecompany_item.id);

                } else {
                    $('#updateCompanyModal_alert').removeClass('alert-success')
                    $('#updateCompanyModal_alert').addClass('alert-danger')
                    $('#updateCompanyModal_alert').removeClass('d-none')
                    $('#updateCompanyModal_alert').addClass('d-block')
                    $('#updateCompanyModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#updateCompanyModal_alert').addClass('alert-success')
                        $('#updateCompanyModal_alert').removeClass('alert-danger')
                        $('#updateCompanyModal_alert').removeClass('d-block')
                        $('#updateCompanyModal_alert').addClass('d-none')
                        $('#updateCompanyModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#updateCompanyModal_alert').removeClass('alert-success')
                $('#updateCompanyModal_alert').addClass('alert-danger')
                $('#updateCompanyModal_alert').removeClass('d-none')
                $('#updateCompanyModal_alert').addClass('d-block')
                $('#updateCompanyModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#updateCompanyModal_alert').addClass('alert-success')
                    $('#updateCompanyModal_alert').removeClass('alert-danger')
                    $('#updateCompanyModal_alert').removeClass('d-block')
                    $('#updateCompanyModal_alert').addClass('d-none')
                    $('#updateCompanyModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post Update API
        async post_update_api() {

            console.log("POST UPDATE API");

            await api.get(`/api/v1/navplus/user/apiAccess` + this.admin_updateapi_item.id, {
                id: this.admin_updateapi_item.id,
                ip_whitelist: this.admin_updateapi.ip_whitelist,
                isEnabled: this.admin_updateapi.isEnabled
            }
            ).then((res) => {
                console.log("POST UPDATE API");
                console.log(res);

                if (res.status == 200) {
                    $('#updateApiModal_alert').removeClass('d-none')
                    $('#updateApiModal_alert').addClass('d-block')
                    $('#updateApiModal_alert').html('Company update!')

                    setTimeout(() => {
                        $('#updateApiModal_alert').removeClass('d-block')
                        $('#updateApiModal_alert').addClass('d-none')
                        $('#updateApiModal_alert').html('')
                    }, 1500);

                    this.admin_updateapi = {
                        passHash: "",
                        ip_whitelist: "",
                        isEnabled: false
                    },
                        this.admin_updateapi_data = null
                    this.admin_updateapi_show = false

                    this.fetch_api_data(this.admin_updateapi_item.id)
                } else {
                    $('#updateApiModal_alert').removeClass('alert-success')
                    $('#updateApiModal_alert').addClass('alert-danger')
                    $('#updateApiModal_alert').removeClass('d-none')
                    $('#updateApiModal_alert').addClass('d-block')
                    $('#updateApiModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#updateApiModal_alert').addClass('alert-success')
                        $('#updateApiModal_alert').removeClass('alert-danger')
                        $('#updateApiModal_alert').removeClass('d-block')
                        $('#updateApiModal_alert').addClass('d-none')
                        $('#updateApiModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#updateApiModal_alert').removeClass('alert-success')
                $('#updateApiModal_alert').addClass('alert-danger')
                $('#updateApiModal_alert').removeClass('d-none')
                $('#updateApiModal_alert').addClass('d-block')
                $('#updateApiModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#updateApiModal_alert').addClass('alert-success')
                    $('#updateApiModal_alert').removeClass('alert-danger')
                    $('#updateApiModal_alert').removeClass('d-block')
                    $('#updateApiModal_alert').addClass('d-none')
                    $('#updateApiModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post Profile
        async post_profile() {

            console.log("POST UPDATE PROFILE");

            await api.get(`/api/v1/navplus/user/edit/` + this.admin_updateprofile_item.id, {
                name: this.admin_updateprofile.name,
                username: this.admin_updateprofile.username,
            }
            ).then((res) => {
                console.log("POST UPDATE PROFILE");
                console.log(res);

                if (res.status == 200) {
                    $('#updateProfileModal_alert').removeClass('d-none')
                    $('#updateProfileModal_alert').addClass('d-block')
                    $('#updateProfileModal_alert').html('Profile update!')

                    setTimeout(() => {
                        $('#updateProfileModal_alert').removeClass('d-block')
                        $('#updateProfileModal_alert').addClass('d-none')
                        $('#updateProfileModal_alert').html('')
                    }, 1500);

                    this.admin_updateprofile = {
                        name: "",
                        username: "",
                        email: "",
                    },
                        this.admin_updateprofile_data = null

                    this.fetch_edit_profile(this.admin_updateprofile_item.id);

                } else {
                    $('#updateProfileModal_alert').removeClass('alert-success')
                    $('#updateProfileModal_alert').addClass('alert-danger')
                    $('#updateProfileModal_alert').removeClass('d-none')
                    $('#updateProfileModal_alert').addClass('d-block')
                    $('#updateProfileModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#updateProfileModal_alert').addClass('alert-success')
                        $('#updateProfileModal_alert').removeClass('alert-danger')
                        $('#updateProfileModal_alert').removeClass('d-block')
                        $('#updateProfileModal_alert').addClass('d-none')
                        $('#updateProfileModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#updateProfileModal_alert').removeClass('alert-success')
                $('#updateProfileModal_alert').addClass('alert-danger')
                $('#updateProfileModal_alert').removeClass('d-none')
                $('#updateProfileModal_alert').addClass('d-block')
                $('#updateProfileModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#updateProfileModal_alert').addClass('alert-success')
                    $('#updateProfileModal_alert').removeClass('alert-danger')
                    $('#updateProfileModal_alert').removeClass('d-block')
                    $('#updateProfileModal_alert').addClass('d-none')
                    $('#updateProfileModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post Permissions
        async post_permis() {

            console.log("POST UPDATE PEMIS");

            await api.get(`/api/v1/navplus/user/edit/permission/` + this.updatepermis_item.id, {
                permissions: this.admin_updatepermis_allowed_arr,
            }
            ).then((res) => {
                console.log("POST UPDATE COMPANY");
                console.log(res);

                if (res.status == 200) {
                    $('#updatePermisModal_alert').removeClass('d-none')
                    $('#updatePermisModal_alert').addClass('d-block')
                    $('#updatePermisModal_alert').html('Permissions update!')

                    setTimeout(() => {
                        $('#updatePermisModal_alert').removeClass('d-block')
                        $('#updatePermisModal_alert').addClass('d-none')
                        $('#updatePermisModal_alert').html('')
                    }, 1500);


                    this.admin_updatepermis_permissions_fitur = null
                    this.admin_updatepermis_allowed = []
                    this.admin_updatepermis_allowed_arr = []

                    this.fetch_permis(this.admin_updatepermis_item.id);

                } else {
                    $('#updatePermisModal_alert').removeClass('alert-success')
                    $('#updatePermisModal_alert').addClass('alert-danger')
                    $('#updatePermisModal_alert').removeClass('d-none')
                    $('#updatePermisModal_alert').addClass('d-block')
                    $('#updatePermisModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#updatePermisModal_alert').addClass('alert-success')
                        $('#updatePermisModal_alert').removeClass('alert-danger')
                        $('#updatePermisModal_alert').removeClass('d-block')
                        $('#updatePermisModal_alert').addClass('d-none')
                        $('#updatePermisModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#updatePermisModal_alert').removeClass('alert-success')
                $('#updatePermisModal_alert').addClass('alert-danger')
                $('#updatePermisModal_alert').removeClass('d-none')
                $('#updatePermisModal_alert').addClass('d-block')
                $('#updatePermisModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#updatePermisModal_alert').addClass('alert-success')
                    $('#updatePermisModal_alert').removeClass('alert-danger')
                    $('#updatePermisModal_alert').removeClass('d-block')
                    $('#updatePermisModal_alert').addClass('d-none')
                    $('#updatePermisModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post Update Password
        async post_update_pass() {

            console.log("POST UPDATE PASSWORD");

            await api.get(`/api/v1/navplus/user/edit/pass/` + this.admin_updatepass_item.id, {

                password: this.admin_updatepass.new_password,
                password_confirmation: this.admin_updatepass.confirmpass,

            }
            ).then((res) => {
                console.log("POST UPDATE RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#updatePasswordModal_alert').removeClass('d-none')
                    $('#updatePasswordModal_alert').addClass('d-block')
                    $('#updatePasswordModal_alert').html('Password Updated')

                    setTimeout(() => {
                        $('#updatePasswordModal_alert').removeClass('d-block')
                        $('#updatePasswordModal_alert').addClass('d-none')
                        $('#updatePasswordModal_alert').html('')
                    }, 1500);

                    this.admin_updatepass =
                    {
                        new_password: "",
                        confirmpass: "",
                    }

                } else {
                    $('#updatePasswordModal_alert').removeClass('alert-success')
                    $('#updatePasswordModal_alert').addClass('alert-danger')
                    $('#updatePasswordModal_alert').removeClass('d-none')
                    $('#updatePasswordModal_alert').addClass('d-block')
                    $('#updatePasswordModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#updatePasswordModal_alert').addClass('alert-success')
                        $('#updatePasswordModal_alert').removeClass('alert-danger')
                        $('#updatePasswordModal_alert').removeClass('d-block')
                        $('#updatePasswordModal_alert').addClass('d-none')
                        $('#updatePasswordModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#updatePasswordModal_alert').removeClass('alert-success')
                $('#updatePasswordModal_alert').addClass('alert-danger')
                $('#updatePasswordModal_alert').removeClass('d-none')
                $('#updatePasswordModal_alert').addClass('d-block')
                $('#updatePasswordModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#updatePasswordModal_alert').addClass('alert-success')
                    $('#updatePasswordModal_alert').removeClass('alert-danger')
                    $('#updatePasswordModal_alert').removeClass('d-block')
                    $('#updatePasswordModal_alert').addClass('d-none')
                    $('#updatePasswordModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Admin Table
        initTable() {
            var root = this
            if (this.admin_table) {
                console.log("MASUK CLEAR TABLE");
                root.admin_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.admin_data);
                this.admin_table = new DataTable('#adminTable', {
                    data: this.admin_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "7%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "15.6%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                             <button id="admin_updatepass_btn"  data-bs-toggle="modal" data-bs-target="#changePasswordModal" `+ (!this.admin_data[meta.row].editable ? 'disabled' : '') + ` id="adname_btn" type="button" class="btn btn-sm ` + (!this.admin_data[meta.row].editable ? 'btn-secondary' : 'btn-primary') + `  rounded-none"><i class="fas fa-cog"></i></button>
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "9.6%",
                            data: 'email', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "8.6%",
                            data: 'username', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "8.6%",
                            data: 'role_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "7.6%",
                            data: 'group_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "10.6%",
                            data: 'isEnabled', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                if (!this.admin_data[meta.row].editable) {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                           
                            </div>
                                `
                                }
                                else {
                                    if (data == 1) {
                                        return `
                                    <div class="p-1 pe-2 h-100 row`+ meta.row + `">
                                    <div class="form-check">
          <input class="form-check-input" type="checkbox" value=""  checked>
          <label class="form-check-label" for="flexCheckDefault">
            Yes
          </label>
        </div>
                                    </div>
                                    `
                                    } else {
                                        return `
                                    <div class="p-1 pe-2 h-100 row`+ meta.row + `">
                                    <div class="form-check">
          <input class="form-check-input" type="checkbox" value=""  >
          <label class="form-check-label" for="flexCheckDefault">
            No
          </label>
        </div>
                                    </div>
                                    `
                                    }
                                }
                            }
                        },
                        {
                            width: "10.6%",
                            data: 'editable', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                                <div class="p-1 h-100">
                                <button id="admin_profile_btn" data-bs-toggle="modal" data-bs-target="#updateProfileModal" `+ (!data ? 'disabled' : '') + ` type="button" class="btn btn-sm ` + (!data ? 'btn-secondary' : 'btn-primary') + ` w-100 rounded-none"><i class="fas fa-edit"></i></button>
                                </div>
                                `
                            }
                        },
                        {
                            width: "10.6%",
                            data: 'editable', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                                <div class="p-1 h-100">
                                <button id="admin_permis_btn" data-bs-toggle="modal" data-bs-target="#updatePermissionModal" `+ (!data ? 'disabled' : '') + ` type="button" class="btn btn-sm ` + (!data ? 'btn-secondary' : 'btn-primary') + ` w-100 rounded-none"><i class="fas fa-cog"></i><i class="fas fa-user"></i></button>
                                </div>
                                `
                            }
                        },
                        {
                            width: "10.6%",
                            data: 'editable', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                                <div class="p-1 h-100">
                                <button id="admin_company_btn" data-bs-toggle="modal" data-bs-target="#updateCompanyModal" `+ (!data ? 'disabled' : '') + ` type="button" class="btn btn-sm ` + (!data ? 'btn-secondary' : 'btn-primary') + ` w-100 rounded-none"><i class="fas fa-cog"></i><i class="fas fa-ship"></i></button>
                                </div>
                                `
                            }
                        },

                    ]
                });


                var mom = this;
                this.admin_table.on('click', '#admin_updatepass_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.admin_table.row(tr);
                    console.log("UPDATE PASS CLICK");
                    console.log(row.data().id);

                    mom.admin_updatepass_item = row.data()

                });


                this.admin_table.on('click', '#admin_company_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.admin_table.row(tr);
                    console.log("UPDATE COMPANY CLICK");
                    console.log(row.data().id);
                    mom.admin_updatecompany_item = row.data()
                    mom.fetch_company(row.data().id)

                });


                this.admin_table.on('click', '#admin_permis_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.admin_table.row(tr);
                    console.log("UPDATE PERMISSION CLICK");
                    console.log(row.data().id);
                    mom.admin_updatepermis_item = row.data()
                    mom.fetch_permis(row.data().id)

                });

                this.admin_table.on('click', '#admin_profile_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.admin_table.row(tr);
                    console.log("UPDATE PROFILE CLICK");
                    console.log(row.data().id);
                    mom.admin_updateprofile_item = row.data()
                    mom.fetch_edit_profile(row.data().id)

                });
            }
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //Search Admin Table
        adminTable_search() {
            console.log(this.search_admin)
            if (this.search_admin != null && this.search_admin.length > 0) {
                this.admin_table.search(this.search_admin).draw();
            }
        },

        //Clear Search Admin Table
        clear_adminTable_search() {
            this.search_admin = ''
            this.admin_table.search(this.search_admin).draw();
        },

        //Show Page Admin Table
        adminTable_showof(page) {
            console.log("PAGE : " + page);
            this.admin_table.page.len(page).draw()
            this.page_off = page;
        },
        //------------------------------------------------------------------



        // TOGGLES FUNCTIONS
        //------------------------------------------------------------------
        //Toggle Show Password Create User
        toggleShow() {
            this.passhow = !this.passhow;
        },

        //Toggle Show Password Confirmation Create User
        toggleConShow() {
            this.con_passhow = !this.con_passhow;
        },

        //Toggle passHash Show
        toggle_passHash_show() {
            if (this.admin_updateapi.passHash.length > 0) {
                this.admin_updateapi_show = !this.admin_updateapi_show
            }

        },

        //Toggle Show Password Update Password
        toggle_up_Show() {
            this.up_passhow = !this.up_passhow;
        },

        //Toggle Show Confirmation Password Update Password
        toggle_up_ConShow() {
            this.up_con_passhow = !this.up_con_passhow;
        },

        //Toggle Show Password Create Owner
        toggle_owner_Show() {
            this.owner_passhow = !this.owner_passhow;
        },

        //Toggle Show Confirmation Password Create Owner
        toggle_owner_ConShow() {
            this.owner_con_passhow = !this.owner_con_passhow;
        },
        //------------------------------------------------------------------


        //INPUT FORMAT CHECKER FUNCTIONS
        //------------------------------------------------------------------
        //Email Formater Create User
        is_email_correct() {

            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

            if (reg.test(this.admin_createuser.email) == false) {
                this.admin_createuser_email_error = true
            } else {
                this.admin_createuser_email_error = false
            }
        },

        //Email Formater Create Owner
        is_owner_email_correct() {

            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

            if (reg.test(this.admin_createowner.email) == false) {
                this.admin_createowner_email_error = true
            } else {
                this.admin_createowner_email_error = false
            }
        },

        //Password Formater Create User
        is_password_correct() {

            if (this.admin_createuser.password == this.admin_createuser.confirmpass) {
                this.admin_createuser_pass_error = false
            } else {
                this.admin_createuser_pass_error = true
            }

        },

        //Password Formater Create Owner
        is_owner_password_correct() {

            if (this.admin_createowner.password == this.admin_createowner.password_confirmation) {
                this.admin_createowner_pass_error = false
            } else {
                this.admin_createowner_pass_error = true
            }

        },

        //Password Formater Update Password
        is_updatepassword_correct() {

            if (this.admin_updatepass.new_password == this.admin_updatepass.confirmpass) {
                this.admin_updatepass_pass_error = false
            } else {
                this.admin_updatepass_pass_error = true
            }

        },
        //------------------------------------------------------------------


        //CLOSE FUNCTIONS
        //------------------------------------------------------------------
        //Close Change Password
        close_change_pass() {
            this.admin_updatepass =
            {
                new_password: "",
                confirmpass: "",
            }
        },

        //Cloase Create User
        close_create_user() {
            this.admin_createuser = {
                name: "",
                username: "",
                email: "",
                vessels: [],
                password: "",
                confirmpass: "",
            },
                this.admin_createuser_vessel = null;
            this.admin_createuser_owner_email = null;
            this.admin_createuser_owner_label = "Select Ship Owner";
        },

        //Close Edit Profile
        close_edit_profile() {
            this.admin_updateprofile = {
                name: "",
                username: "",
                email: "",
            },
                this.admin_updateprofile_data = null
        },

        //Close Create Owner
        close_create_owner() {
            this.admin_createowner = {
                name: "",
                username: "",
                email: "",
                role: "3",
                pts: [],
                password: "",
                password_confirmation: "",
            },
                this.admin_createowner_company = null
        },

        //Close Update Company
        close_update_company() {
            this.admin_updatecompany_item = null
            this.admin_updatecompany_company = null
            this.admin_updatecompany_owned = []
            this.admin_updatecompany_owned_arr = []
        },

        //Close Update Permissions
        close_update_permis() {
            this.admin_updatepermis_item = null
            this.admin_updatepermis_permissions_fitur = null
            this.admin_updatepermis_allowed = []
            this.admin_updatepermis_allowed_arr = []
        },

        //Close Update Api
        close_update_api() {
            this.admin_updateapi = {
                passHash: "",
                ip_whitelist: "",
                isEnabled: false
            },
                this.admin_updateapi_item = null
            this.admin_updateapi_data = null
            this.admin_updateapi_show = false
        },
        //------------------------------------------------------------------


        //BUTTON ACTIVATE FUNCTIONS  
        //------------------------------------------------------------------
        //Button Create User
        create_user_btn() {
            var data_ = {
                name: this.admin_createuser.name,
                username: this.admin_createuser.username,
                email: this.admin_createuser.email,
                password: this.admin_createuser.password,
                role: "4",
                vessels: this.admin_createuser.vessels,
                password_confirmation: this.admin_createuser.confirmpass,
            }

            if (
                data_.name
                && data_.username
                && data_.email
                && data_.vessels.length > 0
                && data_.password
                && data_.password_confirmation
            ) {
                if (this.admin_createuser_pass_error || this.admin_createuser_email_error) {
                    return false
                } else {
                    return true
                }

            } else {
                return false
            }
        },

        //Button Create Owner
        create_owner_btn() {

            var data_ = {
                name: this.admin_createowner.name,
                username: this.admin_createowner.username,
                email: this.admin_createowner.email,
                role: "3",
                pts: this.admin_createowner.pts,
                password: this.admin_createowner.password,
                password_confirmation: this.admin_createowner.password_confirmation,
            }

            if (
                data_.name
                && data_.username
                && data_.email
                && data_.pts.length > 0
                && data_.password
                && data_.password_confirmation
            ) {
                if (this.admin_createowner_pass_error || this.admin_createowner_email_error) {
                    return false
                } else {
                    return true
                }

            } else {
                return false
            }
        },

        //Button Update Password
        update_pass_btn() {
            var data_ = {
                password: this.admin_updatepass.new_password,
                password_confirmation: this.admin_updatepass.confirmpass,
            }
            if (
                data_.password
                && data_.password_confirmation
            ) {
                if (this.admin_updatepass_pass_error) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },

        //Button Update API
        update_api_btn() {
            if (this.admin_updateapi_data) {
                if (
                    (this.admin_updateapi.ip_whitelist == this.admin_updateapi_data.ip_whitelist)
                    &&
                    (this.admin_updateapi.isEnabled == this.admin_updateapi_data.isEnabled)
                ) {
                    return true
                } else {
                    return false
                }
            }

        },

        //Button Update Profile
        edit_profile_btn() {
            if (this.admin_updateprofile_data) {
                if ((this.admin_updateprofile.name == this.admin_updateprofile_data.user.name) && (this.admin_updateprofile.username == this.admin_updateprofile_data.user.username)) {
                    return true
                } else {
                    return false
                }
            }
        },
        //------------------------------------------------------------------


        //ITEM INITIALIZATION FUNCTIONS
        //------------------------------------------------------------------
        //Init Item Password
        update_pass_item() {
            this.admin_updatepass_item = this.admin_updateprofile_item
        },
        //Init Item API
        update_api_item() {
            this.admin_updateapi_item = this.admin_updateprofile_item
            this.fetch_api_data(this.admin_updateprofile_item.id)
        },
        //------------------------------------------------------------------



        //CHECKBOX CLICK FUNCTIONS
        //------------------------------------------------------------------
        //Checkbox Owner
        owner_click(owner) {
            console.log("CLICK OWNER");
            this.admin_createuser_vessel = null
            this.admin_createuser_owner_email = owner.email
            setTimeout(() => {
                this.admin_createuser_vessel = owner.vessels
                this.admin_createuser_owner_label = owner.name
            }, 500);
        },

        //Checkbox Vessel
        vessel_click(id) {
            console.log("CLICK VESSEL");
            console.log(id)
            console.log($('#vesselcheck_' + id).prop('checked'))
            console.log("ARRAY SEBELUM");
            console.log(this.admin_createuser.vessels);


            if ($('#vesselcheck_' + id).prop('checked')) {
                this.admin_createuser.vessels.push(id)
            } else {
                this.admin_createuser.vessels = this.admin_createuser.vessels.filter(e => e !== id);
            }

            console.log("ARRAY SESUDAH");
            console.log(this.admin_createuser.vessels);
        },

        //Checkbox API Enabled
        api_enabled_click() {
            console.log("CLICK ENABLED");
            console.log($('#api_check').prop('checked'))


            if ($('#api_check').prop('checked')) {
                this.admin_updateapi.isEnabled = true
            } else {
                this.admin_updateapi.isEnabled = false
            }
        },

        //Checkbox Company
        up_company_click(id) {
            console.log("CLICK UPDATE COMPANY");
            console.log(id)
            console.log($('#vesselcheck_' + id).prop('checked'))
            console.log("ARRAY SEBELUM");
            console.log(this.admin_updatecompany_owned_arr);
            console.log(JSON.stringify(this.admin_updatecompany_owned_arr) != JSON.stringify(this.admin_updatecompany_owned));


            if ($('#upcompanycheck_' + id).prop('checked')) {
                this.admin_updatecompany_owned_arr.push(id)
            } else {
                this.admin_updatecompany_owned_arr = this.admin_updatecompany_owned_arr.filter(e => e !== id);
            }

            console.log("ARRAY SESUDAH");
            console.log(this.admin_updatecompany_owned_arr);
        },

        //Checkbox Permissions
        up_permis_click(id) {
            console.log("CLICK UPDATE PERMIS");
            console.log(id)
            console.log($('#uppermischeck_' + id).prop('checked'))
            console.log("ARRAY SEBELUM");
            console.log(this.admin_updatepermis_allowed_arr);


            if ($('#uppermischeck_' + id).prop('checked')) {
                this.admin_updatepermis_allowed_arr.push(id)
            } else {
                this.admin_updatepermis_allowed_arr = this.admin_updatepermis_allowed_arr.filter(e => e !== id);
            }

            console.log("ARRAY SESUDAH");
            console.log(this.admin_updatepermis_allowed_arr);
        },

        //Checkbox Company
        company_click(id) {
            console.log("CLICK COMPANY");
            console.log(id)
            console.log($('#companycheck_' + id).prop('checked'))
            console.log("ARRAY SEBELUM");
            console.log(this.admin_createowner);


            if ($('#companycheck_' + id).prop('checked')) {
                this.admin_createowner.pts.push(id)
            } else {
                this.admin_createowner.pts = this.admin_createowner.pts.filter(e => e !== id);
            }

            console.log("ARRAY SESUDAH");
            console.log(this.admin_createowner.pts);
        },
        //------------------------------------------------------------------


        // OTHER FUNCTIONS
        //------------------------------------------------------------------
        //Compare Arrays
        compareTwoArrays(arrfirst, arrsecond) {
            if (arrfirst.length !== arrsecond.length) {
                return false;
            }
            const set1 = new Set(arrfirst);
            const filteredArr2 = arrsecond.filter((value) => set1.has(value));
            return arrfirst.length === filteredArr2.length;
        },
        //------------------------------------------------------------------



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            this.admin_table= null
            this.search_admin= null
            this.admin_data= null
            this.page_off= 10
            this.page_off_arr= [
                10,
                25,
                50,
                100
            ]
            this.th_admin_arr= [
                "No",
                "Name",
                "Email",
                "Username",
                "Role",
                "Group",
                "Enabled",
                "Profile",
                "Permission",
                "Company/Vessel",
            ],
            this.td_admin_arr= [
                11.7,
                11.7,
                11.7,
                11.7,
                11.7,
                11.7,
                11.7,
                6,
                6,
                6,
            ]
            this.td_button=[
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'button_show', b: 'btn-primary' },
                { a: 'button_show', b: 'btn-primary' },
                { a: 'button_show', b: 'btn-primary' },
            ]
            this.admin_createuser=
            {
                name: "",
                username: "",
                email: "",
                vessels: [],
                password: "",
                confirmpass: "",
            }
            this.admin_createowner= {
                name: "",
                username: "",
                email: "",
                role: "3",
                pts: [],
                password: "",
                password_confirmation: "",
            },
            this.admin_updatepass=
            {
                new_password: "",
                confirmpass: "",
            }
            this.admin_updatepass_pass_error= false
            this.admin_updatepass_item= null
            this.admin_createuser_owner_email= null
            this.admin_createuser_owner= null
            this.admin_createuser_owner_label= "Select Ship Owner"
            this.admin_createuser_vessel= null
            this.admin_createuser_email_error= false
            this.admin_createuser_pass_error= false
            this.admin_createowner_company= null
            this.admin_createowner_email_error= false
            this.admin_createowner_pass_error= false
            this.admin_updatecompany_item= null
            this.admin_updatecompany_company= null
            this.admin_updatecompany_owned= []
            this.admin_updatecompany_owned_arr= []
            this.admin_updatepermis_item= null
            this.admin_updatepermis_permis= null
            this.admin_updatepermis_permissions_fitur= null
            this.admin_updatepermis_allowed= []
            this.admin_updatepermis_allowed_arr= []
            this.admin_updateprofile= {
                name: "",
                username: "",
                email: "",
            },
            this.admin_updateprofile_data= null
            this.admin_updateprofile_item= null

            this.admin_updateapi= {
                passHash: "",
                ip_whitelist: "",
                isEnabled: false,
            },
            this.admin_updateapi_item= null
            this.admin_updateapi_show= false
            this.admin_updateapi_data= null
            this.passhow= false
            this.con_passhow= false
            this.up_passhow= false
            this.up_con_passhow= false
            this.owner_passhow= false,
            this.owner_con_passhow= false

        },



    }
})
