import { useAuthStore } from '@/stores';
import { useNotifStore } from '@/stores/modules/notification.store';
export default {
  components: {
  },
  data() {
    return {
      authStore: useAuthStore(),
      notification_store: useNotifStore(),
      version : "3.0.0",
    };
  },
  methods:{
    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
  }
  },
  computed: {
    returnPath() {
      return this.$route.path
    },


  },
};