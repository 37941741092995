import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";


export const useIridiumStore = defineStore({
    id: 'iridium',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        authStore: useAuthStore(),
        iridium_table: null,
        iridiumuser_table: null,
        iridiumtrack_table: null,
        is_showonmap: null,
        search_iridium: null,
        search_iridiumuser: null,
        search_iridiumtrack: null,
        iridium_data: null,
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 6,
        mapiridium_data: null,
        mapiridium_data_date: null,
        page_off: 10,
        page_off_tracking: 10,
        show_range: "Last 24 Hours",
        export_range: "24",
        mapiridium_id: null,
        loading_range: false,
        tracking_layer: L.layerGroup(),
        target_layer: L.layerGroup(),
        link_layer: L.layerGroup(),
        search_layer: L.layerGroup(),
        marker_tracking: [],
        moving_marker: null,
        tracking_path: null,
        tracking_latLng: null,
        tracking_rotation: null,
        interval_tracking: null,
        iridium_map: null,
        date_range: null,
        isBottom: false,
        skychat_icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDQuMjkgMjM4LjM2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMTEuNDgsNjUuOTJIMTkzLjExVjU0LjY1YTI2LjkzLDI2LjkzLDAsMCwwLTI2LjkzLTI2LjkySDU4LjQyTDI4LjgxLDBsLjI3LDI3LjczSDI2LjkyQTI2LjkyLDI2LjkyLDAsMCwwLDAsNTQuNjVWMTI5LjJhMjYuOTIsMjYuOTIsMCwwLDAsMjYuOTIsMjYuOTJINDYuMjR2MTMuNWEzMi44NSwzMi44NSwwLDAsMCwzMi44MSwzMi44MWg5OS42M0wyMTcsMjM4LjM2LDIxNi43LDIwMmEzMi44NSwzMi44NSwwLDAsMCwyNy41OS0zMi4zOVY5OC43M0EzMi44NSwzMi44NSwwLDAsMCwyMTEuNDgsNjUuOTJaTTEwMCw0NS43OXY1NC42N0w0Ni4yOCw5NFpNNDYuMTQsMTI3LjMzbC00LjU0LTExLjVMMTQ1LjI2LDk0LjA2bC0xOC43OSwzMy4yN1ptMTgzLjc0LDQyLjI5YTE4LjQyLDE4LjQyLDAsMCwxLTE4LjQsMTguNGgtOS4zMmwuMTYsMTYuODFMMTg0LjM4LDE4OEg3OS4wNWExOC40MiwxOC40MiwwLDAsMS0xOC40MS0xOC40di0xMy41SDE2Ni4xOGEyNi45MywyNi45MywwLDAsMCwyNi45My0yNi45MlY4MC4zM2gxOC4zN2ExOC40MiwxOC40MiwwLDAsMSwxOC40LDE4LjRaIi8+PC9nPjwvZz48L3N2Zz4=",
        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        th_iridium_arr: [
            "No",
            "Vessel Name",
            "Company",
            "Group",
            "IMEI",
            "Rateplan",
            "",
            ""
        ],

        th_iridium_track_arr: [
            "#",
            "Latitude,Longitude",
            "DMS",
            "Speed\n(Knots)",
            "Heading\n(Degree)",
            "Distance\n(Nmi)",
            "Date/Time\n(UTC)",
            "Date/Time\n(GMT+7)"
        ],
        th_iridium_user_arr: [
            "#",
            "Name",
            "Email",
            "Role",
            "Last Login"
        ],
        td_iridium_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            8.5,
            24.5
        ],
        td_iridium_track_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ],

        th_iridium_skychat_arr: [
            "Name",
            "Email",
            "Username",
            "Role",
            "Enable",
        ],
        td_iridium_skychat_arr: [
            20,
            20,
            20,
            20,
            20,
        ],

        chatroom_data: null,
        chatroom_from: null,
        chatroom_to: null,
        chatroom_body: '',
        chatroom_sending: false,
        chatroom_messages: null,
        chatroom_thurayanumber: null,
        chatroom_item: null,
        chatroom_interval: null,
        chatroom_search: '',

        skychat_user: null,
        skychat_user_item: null,
        skychat_user_table: null,
        skychat_user_checked: [],
        skychat_user_unchecked: [],
        skychat_user_post: null,
        skychat_user_loading: false,

        skychat_user_role_arr: [
            {
                label: 'Full Privilege',
                id: 1,
            },
            {
                label: 'Read Write Chatboard',
                id: 2,
            },
            {
                label: 'Readonly',
                id: 3,
            }
        ],
        idxs: 0,

    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Iridium Data
        async fetch_iridium() {
            await api.get(`/api/v1/navplus/iridium`, {}
            ).then((res) => {
                console.log("IRIDIUM RESPONSE");
                console.log(res);
                if (this.search_iridium == null || this.search_iridium.length == 0) {
                    this.iridium_data = res.data.data.vessels;
                    this.initTable();
                }


            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Iridium Show on Map
        async fetch_IridiumMap(id) {
            this.mapiridium_data = null;
            this.mapiridium_id = id;
            await api.get(`/api/v1/navplus/iridium/` + id, {}
            ).then((res) => {
                console.log("SHOW ON MAP IRIDIUM FETCH");
                console.log(res);
                this.mapiridium_data = res.data.data
                setTimeout(() => {
                    this.initTable_user()
                    this.initTable_tracking(res.data.data)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },

        //Fetch Iridium Show on Map Date
        async fetch_IridiumMap_date(range) {
            this.last_center = [-1.4312313766684295, 119.1498789812993]
            this.loading_range = true
            this.mapiridium_data_date = null
            this.export_range = range.toLowerCase()
            await api.post(`/api/v1/navplus/iridium/date
            `, {
                id: this.mapiridium_id,
                range: range.toLowerCase()
            }
            ).then((res) => {
                console.log("SHOW ON MAP IRIDIUM FETCH DATE");
                console.log(res);
                this.loading_range = false
                this.mapiridium_data_date = res.data.data
                this.moveTracking(res.data.data.tracking)
                this.initTable_tracking(res.data.data)
                console.log("TABLE TRACKING ISINYA");
                if (res.data.data.maptop != null) {
                    this.last_center = [res.data.data.maptop.lat, res.data.data.maptop.lon]
                    this.iridium_map.setView(new L.LatLng(res.data.data.maptop.lat, res.data.data.maptop.lon), 6);
                } else {
                    this.last_center = [-1.4312313766684295, 119.1498789812993]
                    this.iridium_map.setView(new L.LatLng(-1.4312313766684295, 119.1498789812993), 2);
                }

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Chatroom
        async fetch_chatroom(item, isonsend) {

            if (!isonsend) {
                this.chatroom_data = null
                this.chatroom_from = null
                this.chatroom_to = null
                this.chatroom_body = ''
                this.chatroom_messages = null
                this.chatroom_item = null
            }

            // this.chatroom_thurayanumber = null

            await api.get(`api/v1/navplus/skychat/cr/fetch/` + item.id, {}
            ).then((res) => {
                console.log("FETCH SKYCHAT RESPONSE");
                console.log(res);
                // console.log(this.authStore.user.user);

                // this.chatroom_id = item.id
                console.log(this.chatroom_data)
                this.chatroom_data = res.data.data
                this.chatroom_messages = res.data.data.messages
                this.chatroom_from = this.authStore.user.user.username
                this.chatroom_to = res.data.data.name
                this.chatroom_item = item
                this.chatroom_body = ''
                this.chatroom_sending = false

                var mom = this
                // this.chatroom_interval = setInterval(
                //     function () {
                //         if (mom.chatroom_data) {
                //             mom.gradual_chatroom()
                //         } else {
                //             clearInterval(mom.chatroom_interval);
                //         }
                //     }
                //     , 1000);


                setTimeout(() => {
                    var objDiv = document.getElementById("ir_chatroom_content");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }, 500);

            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Skychat User Setting
        async fetch_skychat_user_setting(item, isFirst) {


            if (isFirst) {
                this.skychat_user_checked = []
                this.skychat_user_unchecked = []
                this.skychat_user = null
                this.skychat_user_loading = false
                this.idxs = 0
            }


            if (this.skychat_user_table) {
                this.skychat_user_table.clear().draw();
            }

            await api.get(`api/v1/navplus/skychat/fetch/` + item.id, {}
            ).then((res) => {
                console.log("FETCH SKYCHAT USER SETTING RESPONSE");
                console.log(res);
                var mom = this;
                this.skychat_user = res.data.data
                this.skychat_user_post = res.data.data.vesselUsers
                mom.initTable_skychat_user(res.data.data)
            }).catch((err) => {

            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //CHATROOM ACTIONS FUNCTIONS
        //------------------------------------------------------------------
        //Filter Chatroom 
        filter_chatroom() {
            var message_ = this.chatroom_data.messages
            console.log(" HASIL SEARCH ");
            console.log(message_);


            const filter_message = message_.filter(val => val.body.includes(this.chatroom_search));
            console.log(filter_message);
            if (this.chatroom_search) {
                this.chatroom_messages = filter_message
            } else {
                this.chatroom_messages = this.chatroom_data.messages
            }

        },
        //Close Chatroom
        close_chatroom() {
            if (this.chatroom_interval) {
                clearInterval(this.chatroom_interval);
            }
            this.chatroom_data = null
        },
        //Clear Chatroom
        clear_chatroom_search() {
            this.chatroom_search = ''
            this.chatroom_messages = this.chatroom_data.messages
            clearInterval(this.chatroom_interval);
        },
        //Request Chatroom Data Gradually
        async gradual_chatroom() {
            await api.get(`/api/v1/navplus/sms/fetch/` + this.chatroom_item.id, {}
            ).then((res) => {
                console.log("UPDATE CHATROOM");
                console.log(res);
                this.chatroom_messages = res.data.messages
                if (!this.chatroom_search && this.chatroom_search.length == 0) {
                    this.chatroom_messages = res.data.messages.messages
                }
            }).catch((err) => {

            }).then(() => {
            });


        },
        //------------------------------------------------------------------



        // POST DATA FUNCTIONS
        //------------------------------------------------------------------
        async post_chatroom() {
            var message = this.chatroom_body
            this.chatroom_body = ''
            this.chatroom_sending = true
            await api.post(`/api/v1/navplus/skychat/cr/send`, {
                msg: message,
                vessel_id: this.chatroom_data.id,
            }
            ).then((res) => {
                console.log("POST SMS RESPONSE");
                console.log(res);
                var mom = this
                setTimeout(() => {
                    mom.fetch_chatroom(mom.chatroom_item, true)
                    var objDiv = document.getElementById("ir_chatroom_content");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }, 1000);

            }).catch((err) => {

            }).then(() => {
            });
        },

        async post_skychat_user() {
            this.skychat_user_loading = true;
            await api.post(`/api/v1/navplus/skychat/push`, {
                data: this.skychat_user_post,
                vessel_id: this.skychat_user_item.id
            }
            ).then((res) => {
                console.log("POST SKYCHAT USER RESPONSE");
                console.log(res);
                this.skychat_user_loading = false;
                this.fetch_skychat_user_setting(this.skychat_user_item, false)

            }).catch((err) => {

            }).then(() => {
            });

        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search iridium table
        iridiumTable_search() {
            if (this.search_iridium != null && this.search_iridium.length > 0) {
                this.iridium_table.search(this.search_iridium).draw();
            }
        },
        //search iridium user table
        userTable_search() {
            this.iridiumuser_table.search(this.search_iridiumuser).draw();
        },
        //search iridium tracking table
        trackTable_search() {
            this.iridiumtrack_table.search(this.search_iridiumtrack).draw();
        },
        //data shorting iridium table 
        iridiumTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridium_table.page.len(page).draw()
            this.page_off = page;
        },
        //data shorting iridium table 
        iridiumTrackTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumtrack_table.page.len(page).draw()
            this.page_off_tracking = page;
        },
        //clear iridium table
        clear_iridiumTable_search() {
            this.search_iridium = ''
            this.iridium_table.search(this.search_iridium).draw();
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Iridium Table
        initTable() {
            var root = this
            if (this.iridium_table) {
                console.log("MASUK CLEAR TABLE");
                root.iridium_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.iridium_data);
                this.iridium_table = new DataTable('#iridiumTable', {
                    data: this.iridium_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "3%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "16.4%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "16.4%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "10.4%",
                            data: 'pt_group', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data != null ? data : `-`}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "16.4%",
                            data: 'imei', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                `+ (this.iridium_data[meta.row].skychat ? `<button data-bs-toggle="modal" data-bs-target="#iridiumChatModal"  id="irchat_btn" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-comments"></i></button>` : ``) + `${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "13.4%",
                            data: 'rateplan', className: 'p-0', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "10.4%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                if (this.iridium_data[meta.row].skychat) {
                                    return `
                        
                               <div  class="dropdown">
          <button class="btn btn-sm rounded-none btn-primary dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
            <i class="fas fa-wrench me-1"></i>Config
          </button>

          <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
    
            `+ (this.iridium_data[meta.row].skychat ? `<li><a data-bs-toggle="modal" data-bs-target="#skychatUserModal" id="irschatuser_btn" class="dropdown-item" href="#">Skychat User</a></li>` : ``) + `

          </ul>
        </div>
                              `} else {
                                    return ``
                                }
                            }
                        },
                        {
                            width: "10.4%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button onclick="showonmap_iridium('`+ data + `')"  type="button" class="btn btn-sm btn-primary w-100 rounded-none">Show on Map</button>
                              </div>
                              `
                            }
                        },
                    ]
                });


                var mom = this;

                this.iridium_table.on('click', '#irchat_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("CHATROOM DI CLICK");
                    console.log(row.data());

                    mom.fetch_chatroom(row.data(), false)

                });


                this.iridium_table.on('click', '#irschatuser_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("SKYCHAT USER BUTTON DI CLICK");
                    console.log(row.data());
                    mom.skychat_user_item = row.data()
                    mom.fetch_skychat_user_setting(row.data(), true)

                });




            }
        },
        //Initialize Iridium User
        initTable_user() {
            var root = this
            console.log("INIT TABLE USER");
            console.log(this.iridiumuser_table);
            console.log("MASUK INIT TABLE USER");
            console.log(this.mapiridium_data.vesselUsers);
            this.iridiumuser_table = new DataTable('#iridiumTableUser', {
                data: this.mapiridium_data.vesselUsers,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 5,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "14.25%",
                        data: 'role_name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "34.25%",
                        data: 'lastLogin', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        //Initialize Tracking Table
        initTable_tracking(data) {
            console.log("INIT TABLE TRACKING");
            console.log(this.iridiumtrack_table);
            console.log("MASUK INIT TABLE TRACKING");
            console.log(data.tracking);
            this.iridiumtrack_table = new DataTable('#iridiumTableTrack', {
                data: data.dataTable,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'latlong', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div onclick="popup_tracking('`+ meta.row + `')" class="p-0 h-100 link_tracking">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dms', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'speed', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'heading', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'distance', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'dateTime', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dateTime_local', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `  
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        initTable_skychat_user(data) {
            console.log("INIT TABLE CDR");
            console.log(this.skychat_user_table);
            console.log("MASUK INIT TABLE CDR");

            try {
                this.skychat_user_table = new DataTable('#iridiumTableSkychat', {
                    data: data.vesselUsers,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "20%",
                            data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "20%",
                            data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "20%",
                            data: 'username', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data ? data : '-'}
                                </div>
                                `
                            }
                        },
                        {
                            width: "20%",
                            data: 'skychat', className: 'td_nowrap', render: (data, type, row, meta) => {
                                if (this.skychat_user.vesselUsers[meta.row].username) {
                                    return `
                                <div class="p-1 h-100 w-100">
                                <div  class="dropdown">
          <button ${this.skychat_user.latest_status == 'ON AIR' ? 'disabled' : ''}  id="skychat_user_dropdown_label_${this.skychat_user.vesselUsers[meta.row].id}" class="btn btn-sm w-100 rounded-none btn-light border dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
           ${this.skychat_user_role_arr[data.role_id - 1].label}
          </button>

          <ul  class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
                                    ${this.return_dropdown(meta.row)}
          </ul>
        </div>
                                </div>
                                `
                                } else {
                                    return `
                                <div class="p-1 h-100 w-100">
                                <div  class="dropdown w-100">
          <button disabled  class="btn btn-sm rounded-none btn-light border w-100 dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
          -
          </button>

          <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
    
          </ul>
        </div>
                                </div>
                                `
                                }

                            }
                        },
                        {
                            width: "20%",
                            data: 'id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                if (this.skychat_user.vesselUsers[meta.row].username) {
                                    return `
                                    <div class="p-1 h-100">
                                    <span title="" id="skychat_user_checkdiv_${data}" class="form-check">
                                    ${this.return_checkbox(meta.row)}
                                    </span>
                                    </div>
                                    `
                                } else {
                                    return `
                                    <div class="p-1 h-100"> 
                                    <span id="skychat_no_username_check" title="" class="form-check not_allowed_cursor">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled>
                                    </span>
                                    </div>
                                    `
                                }

                            }
                        },


                    ]
                });

                if (this.thurayaCDR_table) {
                    $("#cdrfilter1").trigger("click");
                }


            } catch (e) {

            }

            var mom = this;
            // this.skychat_user_table.on('click', '#check_sky_user', function (e) {
            //     let tr = e.target.closest('tr');
            //     let row = mom.skychat_user_table.row(tr);

            //     console.log(row.data());



            // });

            if (this.skychat_user_checked.length >= 3) {

                for (let i = 0; i < this.skychat_user_unchecked.length; i++) {
                    $('#check_sky_user_' + this.skychat_user_unchecked[i]).attr("disabled", true);
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).addClass("not_allowed_cursor");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).attr('title', 'Max Active SKYCHAT User of 3 has Exceeded, Please Disabled other first.');
                }
                $('#skychat_no_username_check').attr('title', 'Max Active SKYCHAT User of 3 has Exceeded, Please Disabled other first.');
            } else {
                for (let i = 0; i < this.skychat_user_unchecked.length; i++) {
                    $('#check_sky_user_' + this.skychat_user_unchecked[i]).removeAttr("disabled");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).removeClass("not_allowed_cursor");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).attr('title', '');
                }
                $('#skychat_no_username_check').attr('title', 'Username Required');
            }

        },
        //------------------------------------------------------------------



        //SHOW ON MAP FUNCTIONS
        //------------------------------------------------------------------
        //show on map iridium
        showonMap(id) {
            console.log("IRIDIUM SHOW ON MAP");
            this.is_showonmap = false;
            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {

                if (this.eta_store.tableRoutedetail) {
                    this.eta_store.tableRoutedetail.destroy();
                    this.eta_store.tableRoutedetail = null
                }

                this.is_showonmap = true;
                this.mapiridium_data = null;
                this.mapiridium_data_date = null;
                this.iridium_id = null;
                this.show_range = "Last 24 Hours";
                this.tracking_layer.clearLayers();
                this.target_layer.clearLayers();
                this.link_layer.clearLayers();
                this.fetch_IridiumMap(id);



                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#mapiridium_card").offset().top - 68
                    }, 500);
                }, 10);
                setTimeout(() => {
                    this.fetch_IridiumMap_date("24");
                }, 1000);
            }, 5);
        },
        //close show on map iridium
        closeonMap() {
            this.is_showonmap = null;
            this.mapiridium_id = null;
            this.mapiridium_data = null;
            this.mapiridium_data_date = null;
            this.show_range = "Last 24 Hours";
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.link_layer.clearLayers();
            $('html, body').animate({ scrollTop: '0px' }, 500);

            if (this.eta_store.tableRoutedetail) {
                this.eta_store.tableRoutedetail.destroy();
                this.eta_store.tableRoutedetail = null
            }
        },
        //------------------------------------------------------------------



        //CHANGE RANGE FUNCTIONS
        //------------------------------------------------------------------
        change_range(index) {
            this.show_range = this.range_arr[index].label;
            this.fetch_IridiumMap_date(this.range_arr[index].val);
            this.link_layer.clearLayers();
        },
        //------------------------------------------------------------------



        //MAP ESSENTIALS FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Map
        initializeMap(map_) {
            console.log("MAP INITIALIZED");

            this.iridium_map = map_;
            this.target_layer.addTo(this.iridium_map);
            this.tracking_layer.addTo(this.iridium_map);
            this.link_layer.addTo(this.iridium_map);
            this.search_layer.addTo(this.iridium_map);
            this.iridium_map.scrollWheelZoom.disable();
            var searchLayer = this.search_layer;
            var mymap = this.iridium_map;
            this.eta_store.init_eta(mymap, 'iridium');
            this.eta_store.init_start_eta(mymap, 'iridium', this.mapiridium_data.maptop);
            this.eta_store.init_detail_route(mymap, 'iridium')
            this.eta_store.table_koordinat();
            setTimeout(() => {
                L.Control.geocoder(
                    {
                        defaultMarkGeocode: false

                    }
                ).on('markgeocode', function (e) {
                    console.log(e);
                    console.log(e.geocode.center);
                    console.log(e.geocode.html);
                    mymap.setView(new L.LatLng(e.geocode.center.lat, e.geocode.center.lng), 8);

                    var circle = L.circle([e.geocode.center.lat, e.geocode.center.lng], {
                        color: "#1DC01D",
                        fillColor: "#1DC01D",
                        fillOpacity: 0.0,
                        opacity: 0.0,
                        weight: 0,
                        radius: 1600,
                    }).bindPopup(
                        e.geocode.html.length > 0 ?
                            e.geocode.html : e.geocode.name
                    )

                    circle.on("add", function (event) {
                        event.target.openPopup();
                    });

                    circle.addTo(searchLayer)
                }).addTo(this.iridium_map);
            }, 500);

        },
        //Set View Map
        setview_map() {
            this.iridium_map.setView(new L.LatLng(this.mapiridium_data_date.maptop.lat, this.mapiridium_data_date.maptop.lon), 6);
        },
        //On Map Click
        onmap_click() {
            this.search_layer.clearLayers();
        },
        //Fullscreen Options
        fullscreen_scroll() {
            console.log("CLICK FULL");
            this.iridium_map.invalidateSize();
            if (this.iridium_map.isFullscreen() == true) {
                this.iridium_map.scrollWheelZoom.enable();
            } else {
                this.iridium_map.scrollWheelZoom.disable();
            }
        },
        //Return Icon Moving Marker
        return_track_icon() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#0000FF" 
                stroke-width="0"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M50.8,56.4L30.6,43.7c-1.3-0.8-3-0.8-4.3,0L6.2,56.4c-3.4,2.2-7.5-1.6-5.7-5.2L24.9,2.2c1.5-3,5.7-3,7.2,0l24.5,48.9
                C58.4,54.8,54.3,58.5,50.8,56.4z" fill="#0000FF"></path>
                </svg>`,
                className: "",
                iconSize: [57 / 3.5, 57 / 3.5],
                iconAnchor: [57 / 3.5 / 2, 57 / 3.5 / 2],
            });
            return svgIcon
        },
        //Return Icon Iridium Marker
        return_icon(maptop) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                    <svg
                    stroke="#FFF" 
                    stroke-width="10"
                    viewBox="0 0 40 100"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="`+ maptop.fill + `"></path>
                    </svg>`,
                className: "",
                iconSize: [40 / 5, 100 / 5],
                iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
            });

            return svgIcon;


        },
        //Popup Tracking
        popup_tracking(index) {
            console.log(index);
            this.link_layer.clearLayers();
            var circle = L.circle([this.marker_tracking[index].lat, this.marker_tracking[index].lng], {
                color: "#1DC01D",
                fillColor: "#1DC01D",
                fillOpacity: 0.0,
                opacity: 0.0,
                weight: 0,
                radius: 1600,
            }).bindPopup(
                "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lat +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lng +
                "</td></tr><tr>" +
                "<td><b>DMS</b></td><td>:</td>" +
                "<td>" +
                this.marker_tracking[index].dms +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                this.marker_tracking[index].speed +
                "&nbsp;knots </td>" +
                "</tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].heading +
                "</td>" +
                " </tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].updated +
                "</td>" +
                "</tr>" +
                "</table>"
            )

            circle.on("add", function (event) {
                event.target.openPopup();
            });

            this.iridium_map.setView(new L.LatLng(this.marker_tracking[index].lat, this.marker_tracking[index].lng), 6);

            circle.addTo(this.link_layer)


            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#map_iridium").offset().top) - 68
                    }, 500);
                }, 10);
            }, 10);
        },
        //------------------------------------------------------------------



        //MOVING MARKER FUNCTIONS
        //-----------------------------------------------------------------
        //fetch tracking 24 hour marker clicked
        moveTracking(data_tracking) {
            clearInterval(this.interval_tracking);
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            console.log("MASUK KE MOVE TRACKING");
            console.log(data_tracking);
            this.marker_tracking = data_tracking;
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.moving_marker = null;

            if (data_tracking.length > 0) {
                this.moving_marker = L.marker([data_tracking[0].lat, data_tracking[0].lng], {
                    icon: this.return_track_icon(),
                    rotationAngle: data_tracking[0].heading,
                }).addTo(this.target_layer);

                var firstpolyline = new L.Polyline(data_tracking, {
                    color: '#FEE800',
                    weight: 6,
                    opacity: 0.5,
                    smoothFactor: 1
                });
                this.tracking_layer.addLayer(firstpolyline);
                var mom = this
                var i = data_tracking.length
                setTimeout(() => {
                    data_tracking.forEach(function (coord) {
                        i--
                        if (i != 0) {
                            if (mom.authStore.userAllowed && !mom.authStore.userAllowed.allowed['hide marker dot']) {
                                var circle = L.circle([coord.lat, coord.lng], {
                                    color: "#1DC01D",
                                    fillColor: "#1DC01D",
                                    fillOpacity: 0.3,
                                    opacity: 0.6,
                                    weight: 2,
                                    radius: 1600,
                                }).bindPopup(
                                    "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                                    coord.lat +
                                    "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                                    coord.lng +
                                    "</td></tr><tr>" +
                                    "<td><b>DMS</b></td><td>:</td>" +
                                    "<td>" +
                                    coord.dms +
                                    "</td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    "<td><b>Speed</b></td>" +
                                    "<td>:</td>" +
                                    " <td>" +
                                    coord.speed +
                                    "&nbsp;knots </td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    " <td><b>Heading</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.heading +
                                    "</td>" +
                                    " </tr>" +
                                    "<tr>" +
                                    "<td><b>Last Update</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.updated +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>"
                                ).addTo(mom.target_layer)
                            }
                        }
                    });
                }, 1600);
                this.initialdata_moving();
                this.movingStart();
            }


        },
        //moving marker
        initialdata_moving() {
            this.tracking_path = this.marker_tracking.slice()
            if (this.tracking_path[0]) {
                this.tracking_latLng = L.latLng([this.tracking_path[0].lat, this.tracking_path[0].lng])
                this.tracking_rotation = this.tracking_path[0].heading
            }

        },
        //moving marker
        movingStart() {
            var mother = this
            this.interval_tracking = setInterval(function () {
                mother.simulate()
            }, 250);
        },
        //Simulate
        simulate() {
            if (this.tracking_path && this.tracking_path.length > 0) {
                let point = this.tracking_path.shift()
                this.tracking_latLng = L.latLng([point.lat, point.lng])
                this.tracking_rotation = point.heading
                this.moving_marker.setLatLng(this.tracking_latLng)
                this.moving_marker.slideTo(this.tracking_latLng, {
                    duration: 500,
                });
                this.moving_marker.options.rotationAngle = this.tracking_rotation;
            } else {
                this.target_layer.removeLayer(this.moving_marker);
                clearInterval(this.interval_tracking);
                this.tracking_path = null;
            }

        },
        //------------------------------------------------------------------

        //OTHER FUNCTION
        //-----------------------------------------------------------------
        return_dropdown(index) {

            var obj = '';
            for (let i = 0; i < this.skychat_user_role_arr.length; i++) {
                obj = obj + `<li><a onclick="roledown_click('` + this.skychat_user.vesselUsers[index].id + `','` + index + `','` + this.skychat_user_role_arr[i].id + `')"  class="dropdown-item" href="#">${this.skychat_user_role_arr[i].label}</a></li>`;
            }
            return obj;
        },

        return_checkbox(index) {
            var obj = '';
            var mom = this;
            this.idxs = this.idxs + 1;


            console.log("INDEX KE :" + this.idxs);
            console.log("INDEX TABLE :" + index);
            console.log("ID :" + this.skychat_user.vesselUsers[index].id);
            console.log("ISENABLED :" + this.skychat_user.vesselUsers[index].skychat.isEnabled);
            console.log("-----------");


            if (this.skychat_user.vesselUsers[index].skychat.isEnabled != false) {
                console.log(this.skychat_user_checked.includes(this.skychat_user.vesselUsers[index].id))

                if (this.skychat_user_checked.includes(this.skychat_user.vesselUsers[index].id) == false) {
                    console.log("PUSH CHECKED")
                    this.skychat_user_checked.push(this.skychat_user.vesselUsers[index].id)
                }

                obj = `<input id="check_sky_user_` + mom.skychat_user.vesselUsers[index].id + `" onchange="onchange_click('` + mom.skychat_user.vesselUsers[index].id + `','` + index + `')" class="form-check-input pointer_cursor" type="checkbox" value="" checked ${this.skychat_user.latest_status == 'ON AIR' ? ' disabled' : ''} >`
            } else {
                if (this.skychat_user_unchecked.includes(this.skychat_user.vesselUsers[index].id) == false) {
                    console.log("PUSH UNCHECKED")
                    this.skychat_user_unchecked.push(this.skychat_user.vesselUsers[index].id)
                }
                if (this.skychat_user.latest_status == 'ON AIR') {
                    obj = `<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled>`
                } else {
                    obj = `<input  id="check_sky_user_` + mom.skychat_user.vesselUsers[index].id + `" onchange="onchange_click('` + mom.skychat_user.vesselUsers[index].id + `','` + index + `')" class="form-check-input pointer_cursor" type="checkbox" value="">`
                }

            }
            console.log("---------");
            return obj;
        },
        //-----------------------------------------------------------------


        // USER SKYCHAT FUNCTIONS
        //------------------------------------------------------------------
        onchange_click(id, index) {
            console.log("CHECK SKY USER");
            console.log(id);
            console.log("SKYCHAT USER CHECKBOX DI CLICK");
            console.log(index);
            console.log(this.skychat_user_post[index].skychat.isEnabled)
            var id_ = parseInt(id);
            this.skychat_user_post[index].skychat.isEnabled = $('#check_sky_user_' + id_).prop('checked');
            console.log(this.skychat_user_post[index].skychat.isEnabled)

            if ($('#check_sky_user_' + id_).prop('checked')) {
                console.log("SKYCHAT USER CHECKED")
                if (!this.skychat_user_checked.includes(id_)) {
                    this.skychat_user_checked.push(id_)
                    console.log("SKYCHAT USER CHECKED PUSH KE ARRAY")
                }

                if (this.skychat_user_unchecked.includes(id_)) {
                    this.skychat_user_unchecked = this.skychat_user_unchecked.filter(e => e !== id_)
                    console.log("SKYCHAT USER UNCHECKED REMOVE DARI ARRAY")
                }

            } else {
                console.log("SKYCHAT USER UNCHECKED")
                if (!this.skychat_user_unchecked.includes(id_)) {
                    this.skychat_user_unchecked.push(id_)
                    console.log("SKYCHAT USER UNCHECKED PUSH KE ARRAY")
                }

                if (this.skychat_user_checked.includes(id_)) {
                    this.skychat_user_checked = this.skychat_user_checked.filter(e => e !== id_)
                    console.log("SKYCHAT USER CHECKED REMOVE DARI ARRAY")
                }

            }


            if (this.skychat_user_checked.length >= 3) {
                for (let i = 0; i < this.skychat_user_unchecked.length; i++) {
                    $('#check_sky_user_' + this.skychat_user_unchecked[i]).attr("disabled", true);
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).addClass("not_allowed_cursor");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).attr('title', 'Max Active SKYCHAT User of 3 has Exceeded, Please Disabled other first.');
                }
                $('#skychat_no_username_check').attr('title', 'Max Active SKYCHAT User of 3 has Exceeded, Please Disabled other first.');
            } else {
                for (let i = 0; i < this.skychat_user_unchecked.length; i++) {
                    $('#check_sky_user_' + this.skychat_user_unchecked[i]).removeAttr("disabled");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).removeClass("not_allowed_cursor");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).attr('title', '');
                }
                $('#skychat_no_username_check').attr('title', 'Username Required');
            }
        },


        roledown_click(id, index, value) {
            console.log("CHANGE ROLE ID SKY USER");
            console.log(id);
            console.log("SKYCHAT USER CHECKBOX DI CLICK");
            console.log(index);
            console.log(this.skychat_user_post[index].id);
            console.log(this.skychat_user_post[index].skychat.role_id)
            this.skychat_user_post[index].skychat.role_id = value;
            console.log(this.skychat_user_post[index].skychat.role_id);
            $("#skychat_user_dropdown_label_" + this.skychat_user_post[index].id).html(this.skychat_user_role_arr[value - 1].label)

        },
        //------------------------------------------------------------------

        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            this.iridium_table = null;
            this.iridiumuser_table = null;
            this.iridiumtrack_table = null;
            this.is_showonmap = null;
            this.search_iridium = null;
            this.search_iridiumuser = null;
            this.search_iridiumtrack = null;
            this.iridium_data = null;
            this.last_center = [-1.4312313766684295, 119.1498789812993];
            this.last_zoom = 6;
            this.mapiridium_data = null;
            this.mapiridium_data_date = null;
            this.page_off = 10;
            this.page_off_tracking = 10;
            this.show_range = "Last 24 Hours";
            this.export_range = "24";
            this.mapiridium_id = null;
            this.loading_range = false;
            this.tracking_layer = L.layerGroup();
            this.target_layer = L.layerGroup();
            this.link_layer = L.layerGroup();
            this.search_layer = L.layerGroup();
            this.marker_tracking = [];
            this.moving_marker = null;
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            this.interval_tracking = null;
            this.iridium_map = null;
            this.date_range = null;
            this.isBottom = false;
            this.range_arr = [
                { label: "Today", val: "today" },
                { label: "Last 24 Hours", val: "24" },
                { label: "Last 7 Days", val: "7" },
                { label: "Last 30 Days", val: "30" },
                { label: "This Week", val: "week" },
                { label: "This Month", val: "month" },
                { label: "Last Month", val: "lmonth" },
                { label: "Custom Range", val: "24" },
            ];
            this.page_off_arr = [
                10,
                25,
                50,
                100
            ];

            this.th_iridium_arr = [
                "No",
                "Vessel Name",
                "Company",
                "IMEI",
                "Rateplan",
                ""
            ];
            this.th_iridium_track_arr = [
                "#",
                "Latitude,Longitude",
                "DMS",
                "Speed\n(Knots)",
                "Heading\n(Degree)",
                "Distance\n(Nmi)",
                "Date/Time\n(UTC)",
                "Date/Time\n(GMT+7)"
            ];
            this.th_iridium_user_arr = [
                "#",
                "Name",
                "Email",
                "Role",
                "Last Login"
            ];
            this.td_iridium_arr = [
                0,
                0,
                0,
                0,
                0,
                24.5
            ];
            this.td_iridium_track_arr = [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];

            this.th_iridium_skychat_arr = [
                "Name",
                "Email",
                "Username",
                "Role",
                "Enable",
            ];
            this.td_iridium_skychat_arr = [
                20,
                20,
                20,
                20,
                20,
            ];

            this.chatroom_data = null;
            this.chatroom_from = null;
            this.chatroom_to = null;
            this.chatroom_body = '';
            this.chatroom_sending = false;
            this.chatroom_messages = null;
            this.chatroom_thurayanumber = null;
            this.chatroom_item = null;
            this.chatroom_interval = null;
            this.chatroom_search = '';

            this.skychat_user = null;
            this.skychat_user_item = null;
            this.skychat_user_table = null;
            this.skychat_user_checked = [];
            this.skychat_user_unchecked = [];
            this.skychat_user_post = null;
            this.skychat_user_loading = false;

            this.skychat_user_role_arr = [
                {
                    label: 'Full Privilege',
                    id: 1,
                },
                {
                    label: 'Read Write Chatboard',
                    id: 2,
                },
                {
                    label: 'Readonly',
                    id: 3,
                }
            ];
            this.idxs = 0;
        }
        //------------------------------------------------------------------



    }
})
