import { render, staticRenderFns } from "./MapSailink_view.vue?vue&type=template&id=4c471124&"
import script from "@/assets/js/scripts/mapsailink.js?vue&type=script&lang=js&"
export * from "@/assets/js/scripts/mapsailink.js?vue&type=script&lang=js&"
import style0 from "./MapSailink_view.vue?vue&type=style&index=0&id=4c471124&prod&scopped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports