import { defineStore } from 'pinia'
import {useSailinkStore, useThurayaStore ,useOrbCommStore, useGlobalStarStore,useIridiumStore, useSailoggerStore, useManifestStore } from '../index';
import $ from "jquery";
import moment from 'moment';



export const useCustomRangeStore = defineStore({
    id: 'custoom_range',
    state: () => ({
        sailink_store : useSailinkStore(),
        sailogger_store : useSailoggerStore(),
        thuraya_store: useThurayaStore(),
        manifest_store:useManifestStore(),
        orbcomm_store: useOrbCommStore(),
        globalstar_store : useGlobalStarStore(),
        iridium_store : useIridiumStore(),
        onclick_range: '',
        input_name : ""
    }),
    actions: {
        //INITIALIZE ONCLICK STATUS
        //------------------------------------------------------------------
        //Set Onclick Range
        set_onclick_range(onactif) {
            this.onclick_range = onactif
            this.input_name = onactif+"daterange_input"
            var mother = this;

            console.log("INPUT NAME");
            console.log(this.input_name);
            console.log("INPUT NAME");
            console.log(this.onclick_range);
            
            

            setTimeout(() => {

            if(mother.onclick_range == 'thuraya_cdr'){
                $('#footer_range_other').removeClass('d-block')
                $('#footer_range_other').addClass('d-none')

                $('#close_range_other').removeClass('d-block')
                $('#close_range_other').addClass('d-none')

                $('#footer_range_cdr').removeClass('d-none')
                $('#footer_range_cdr').addClass('d-block')

                $('#close_range_cdr').removeClass('d-none')
                $('#close_range_cdr').addClass('d-block')

            }else{
                $('#footer_range_other').removeClass('d-none')
                $('#footer_range_other').addClass('d-block')

                $('#close_range_other').removeClass('d-none')
                $('#close_range_other').addClass('d-block')

                $('#footer_range_cdr').removeClass('d-block')
                $('#footer_range_cdr').addClass('d-none')

                $('#close_range_cdr').removeClass('d-block')
                $('#close_range_cdr').addClass('d-none')
            }
               
            $(function () {
                $('input[name="' + mother.input_name + '"]').daterangepicker({
                    timePicker: true,
                    startDate: moment().startOf('hour'),
                    endDate: moment().startOf('hour').add(24, 'hour'),
                    locale: {

                        format: 'YYYY/MM/DD hh:mm A'
                    }
                });
            });
            }, 100);
        },
        //------------------------------------------------------------------

        //PROCESS RANGE
        //------------------------------------------------------------------
        //Process Range 
        custom_range() {
            var start_year = $('#'+this.input_name).data('daterangepicker').startDate._d.getFullYear();
            var start_month = $('#'+this.input_name).data('daterangepicker').startDate._d.getMonth() + 1;
            var start_date = $('#'+this.input_name).data('daterangepicker').startDate._d.getDate();
            var start_hours = $('#'+this.input_name).data('daterangepicker').startDate._d.getHours();
            var start_minutes = $('#'+this.input_name).data('daterangepicker').startDate._d.getMinutes();

            var end_year = $('#'+this.input_name).data('daterangepicker').endDate._d.getFullYear();
            var end_month = $('#'+this.input_name).data('daterangepicker').endDate._d.getMonth() + 1;
            var end_date = $('#'+this.input_name).data('daterangepicker').endDate._d.getDate();
            var end_hours = $('#'+this.input_name).data('daterangepicker').endDate._d.getHours();
            var end_minutes = $('#'+this.input_name).data('daterangepicker').endDate._d.getMinutes();

            var date1 = start_year + '/'
                + ('0' + start_month).slice(-2) + '/'
                + ('0' + start_date).slice(-2);
            var time1 = ('0' + start_hours).slice(-2) + ':' + ('0' + start_minutes).slice(-2);

            var date2 = end_year + '/'
                + ('0' + end_month).slice(-2) + '/'
                + ('0' + end_date).slice(-2);
            var time2 = ('0' + end_hours).slice(-2) + ':' + ('0' + end_minutes).slice(-2);


            var range_date = date1 + ' ' + time1 + ' - ' + date2 + ' ' + time2;
            var range_date_label = 'Custom Range';

            this.show_range = range_date_label;

            if(this.onclick_range == 'sailink'){
                this.sailink_store.fetch_SailinkMap_date(range_date);
                this.sailink_store.link_layer.clearLayers();
            }

            if(this.onclick_range == 'thuraya'){
                this.thuraya_store.fetch_ThurayaMap_date(range_date);
                this.thuraya_store.link_layer.clearLayers();
            }

            if(this.onclick_range == 'rpmreport'){
                this.sailogger_store.change_range(range_date,range_date);
                
            }


            if(this.onclick_range == 'manifest'){
                this.manifest_store.change_range(range_date,range_date);
                
            }
            
           
            if(this.onclick_range == 'thuraya_cdr'){
                this.thuraya_store.changerange_cdr(range_date,range_date)
            }

            if (this.onclick_range == 'orbcomm') {
                this.orbcomm_store.fetch_OrbcommMap_date(range_date);
                this.orbcomm_store.link_layer.clearLayers();
            }
            
            if (this.onclick_range == 'globalstar') {
                this.globalstar_store.fetch_globalstarmap_date(range_date);
                this.globalstar_store.link_layer.clearLayers();
            }
            
            if(this.onclick_range == 'iridium'){
                this.iridium_store.fetch_IridiumMap_date(range_date);
                this.iridium_store.link_layer.clearLayers();
            }

        },
        //------------------------------------------------------------------


        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            this.onclick_range= '',
            this.input_name = ""
        }
        //------------------------------------------------------------------

        
    }
})
