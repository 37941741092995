import { useThurayaStore, useEtaStore, useCustomRangeStore, useAuthStore } from '@/stores';
import MapThuraya from '@/views/showonmap/MapThuraya_view.vue';
import range_modal from '@/components/modals/Range_Modal.vue';
import $ from "jquery";

export default {
  name: "Thuraya",
  components: {
    MapThuraya,
    range_modal
  },
  data() {
    return {
      thuraya_store: useThurayaStore(),
      customrange_store : useCustomRangeStore(),
      eta_store: useEtaStore(),
      auth_store:useAuthStore(),
      MapThurayaComp: MapThuraya,
      range_modal: range_modal,
      map: null,
      marker: null,
    }
  },

  mounted() {
    this.thuraya_store.fetch_thuraya()
    var mother = this
    window.showonmap_thuraya = function (id) {
      mother.thuraya_store.showonMap(id);
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        mother.thuraya_store.isBottom = true
      } else {
        mother.thuraya_store.isBottom = false
      }
    });
  },

  methods: {

  },

  watch: {
  },
  computed: {
    chatroom_character() {
      return 130 - (this.thuraya_store.chatroom_data.messages.sender.length + this.thuraya_store.chatroom_body.length);
    },
  }
};